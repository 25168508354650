import * as React from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom'

import { useKeycloak } from '@react-keycloak/web'

import HomePage from '../components/Home'
import SettingPage from '../components/settings/Settings'

import {RequiredAuth, RequiredReg} from './utils'
import ProductsPage from "../components/products/Products";
import {LinearProgress} from "@mui/material";
import PricingPage from "../components/marketing/PricingPage";
import StripePaymentResultPage from "../components/marketing/stripe/StripePaymentResultPage";
import {useEffect} from "react";
import {updateCurrentPlan, updateSubscriptions, failedToCheckSubscriptions} from "../redux/slices/consumerSubscriptionSlice";
import {useAppDispatch, useAxios} from "../utils/hooks";
import {useCookies} from "react-cookie";
import {
    CE_API_BASE_URL,
    MARKETING_ATTRIBUTES_COOKIES_KEY_NAME
} from "../utils/constants";
import {parsePlanFromKeycloak} from "../utils/isExtensionPremium";
import WelcomePage from "../components/onboarding/WelcomePage";
import EditorPage from "../components/editor/EditorPage";
import DocumentsPage from '../components/documents/DocumentsPage'
import ExtensionUninstallationGoodbyePage from "../components/ExtensionUninstallationGoodbyePage";
import useCeMetrics from "../metrics/useCeMetrics";
import MiddleHandler from "./MiddleHandler";
import {
    DOCUMENTS_PAGE_ENDPOINT,
    EDITOR_PAGE_ENDPOINT, EXTENSION_UNINSTALLATION_PAGE_ENDPOINT,
    HOME_PAGE_ENDPOINT,
    MIDDLE_HANDLER_ENDPOINT, PLAN_PAGE_ENDPOINT, PLAN_RESULT_PAGE_ENDPOINT, PRODUCTS_PAGE_ENDPOINT,
    SETTINGS_PAGE_ENDPOINT,
    WELCOME_PAGE_ENDPOINT
} from "./endpointConstants";
import {getCeSharedSubDomain} from "../utils/utils";

export const AppRouter = () => {
    const { initialized, keycloak } = useKeycloak()

    const {readTrackSessionToken, setTrackSessionToken,
        sendMarketingParamsToApiWithAuth} = useCeMetrics(keycloak);

    const dispatch = useAppDispatch();

    const axios = useAxios(CE_API_BASE_URL, keycloak);
    const [cookies, , removeCookie] = useCookies([
        MARKETING_ATTRIBUTES_COOKIES_KEY_NAME]);

    useEffect(() => {

        if (initialized && !!keycloak) {
            dispatch(updateCurrentPlan(parsePlanFromKeycloak(keycloak)));

            axios?.current?.post(
                "/subscription"
            ).then(r => {
                dispatch(updateSubscriptions(r?.data));
                keycloak.updateToken(300)
                    .then(refreshed => {
                        if (refreshed) {
                            dispatch(
                                updateCurrentPlan(
                                    parsePlanFromKeycloak(keycloak)));
                        }
                    });
            }).catch(e => dispatch(failedToCheckSubscriptions()));

            !!cookies[MARKETING_ATTRIBUTES_COOKIES_KEY_NAME] && sendMarketingParamsToApiWithAuth([{
                type: "marketing_params_sync", data: cookies[MARKETING_ATTRIBUTES_COOKIES_KEY_NAME]
            }], () => {
                const parsedDomain = getCeSharedSubDomain();
                removeCookie(MARKETING_ATTRIBUTES_COOKIES_KEY_NAME, {domain: parsedDomain})
            });

            // request track session token
            const existingTrackSessionToken = readTrackSessionToken();
            // TODO we can add authenticated check here to determine if we need to re-get a token
            if (typeof existingTrackSessionToken !== 'string') {
                fetch(CE_API_BASE_URL + "/track/session/token", {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                    .then(r => r?.text())
                    .then(t => setTrackSessionToken(t));
            }


            // send out the event, if applicable | deprecated, but keep it for potential usage
            // const trackSessionToken = readTrackSessionToken();
            // const trackEvent = readTrackEventFromCookies();
            // if ((typeof trackSessionToken === 'string')
            //     && typeof trackEvent !== 'undefined'
            //     && (typeof trackEvent === 'object')
            //     && trackEvent !== null
            //     // && trackEvent['attempt'] !== true
            // ) {
            //     sendEventToApiWithoutAuth(trackSessionToken, [(!!cookies && !!trackEvent)? Object.assign(trackEvent, cookies) : trackEvent])?.then(
            //         r => {
            //             if (r?.status === 200) {
            //                 //TODO enable the removals
            //                 // alert("send event successfully: " + JSON.stringify(!!cookies? Object.assign(trackEvent, cookies) : trackEvent))
            //                 // @ts-ignore
            //                 // removeTrackEventCookies();
            //                 // @ts-ignore
            //                 // removeTrackSessionTokenCookies();
            //             }
            //         }
            //     );
            // }
        }
    }, [initialized, keycloak]);

    if (!initialized) {
        return <LinearProgress color="warning" />
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path={WELCOME_PAGE_ENDPOINT + "/*"} element={<WelcomePage />}/>
                <Route path={MIDDLE_HANDLER_ENDPOINT} element={<MiddleHandler />}/>
                <Route path={HOME_PAGE_ENDPOINT} element={<RequiredAuth><HomePage /></RequiredAuth>} />
                <Route path={SETTINGS_PAGE_ENDPOINT} element={<RequiredAuth><SettingPage /></RequiredAuth>} />
                <Route path={PRODUCTS_PAGE_ENDPOINT} element={<RequiredAuth><ProductsPage /></RequiredAuth>} />
                <Route path={PLAN_PAGE_ENDPOINT} element={<RequiredReg><PricingPage /></RequiredReg>} />
                <Route path={PLAN_RESULT_PAGE_ENDPOINT + "/*"} element={<RequiredAuth><StripePaymentResultPage /></RequiredAuth>} />
                <Route path={EDITOR_PAGE_ENDPOINT} element={<RequiredAuth><EditorPage /></RequiredAuth>} />
                <Route path={DOCUMENTS_PAGE_ENDPOINT} element={<RequiredAuth><DocumentsPage /></RequiredAuth>} />
                <Route path={EXTENSION_UNINSTALLATION_PAGE_ENDPOINT} element={<ExtensionUninstallationGoodbyePage />}/>
                <Route path="/*" element={<RequiredAuth><ProductsPage /></RequiredAuth>} />
            </Routes>
        </BrowserRouter>
    )
};

