import React from "react";

type Props = {
  title?: string;
  description: string | React.ReactElement;
  style?: React.CSSProperties;
};

const CarouselDescription: React.FC<Props> = ({
  title,
  description,
  style,
}) => {
  return (
    <div style={style}>
      {
        !!title && <h3 style={{ fontSize: "25px", textAlign: "left" }}>{title}</h3>
      }

      {typeof description === "string" ? (
        <p style={{ fontSize: "18px", textAlign: "left" }}>{description}</p>
      ) : (
        <div style={{ fontSize: "18px", textAlign: "left" }}>{description}</div>
      )}
    </div>
  );
};

export default CarouselDescription;
