import {AddJSLibraryWithLink} from "../../utils/utils";

const Gtag = {
    initialized: (url: string, tagID: string) => {
        AddJSLibraryWithLink(url);

        const script = document.createElement('script');
        script.innerHTML = "window.dataLayer = window.dataLayer || [];\n" +
            "     function gtag(){dataLayer.push(arguments)};\n" +
            "     gtag('js', new Date());\n" +
            "     gtag('config', '" + tagID + "');";
        document.body.append(script);
    },

    recordEvent: (...args:any) => {
        if (typeof window['gtag'] === 'function') {
            window['gtag'](...args);
        }
    }
}

export default Gtag;