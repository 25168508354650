import React, {useEffect} from "react";
import {EXTENSION_UNINSTALL_REDIRECT_URL} from "../utils/constants";
import useCeMetrics from "../metrics/useCeMetrics";
import {useKeycloak} from "@react-keycloak/web";
import {browserName} from "react-device-detect";
import {ParsedToken} from "../utils/types/keycloakExtraType";

const ExtensionUninstallationGoodbyePage: React.FC = () => {
    const { keycloak } = useKeycloak();
    const BROWSER = browserName;
    const parsedToken: ParsedToken | undefined = keycloak?.tokenParsed;

    const {readTrackSessionToken,
        sendEventToApiWithAuth,
        sendEventToApiWithoutAuth} = useCeMetrics(keycloak);
    const trackSessionToken = readTrackSessionToken();

    const events = [{
        type: "EXTENSION_UNINSTALL",
        data: {
            username: keycloak?.authenticated ? parsedToken?.email : "non_logged_in",
            browser: BROWSER,
        }
    }];

    useEffect(() => {

        if (typeof trackSessionToken !== 'undefined' && trackSessionToken != null) {
            if (keycloak?.authenticated) {
                sendEventToApiWithAuth(trackSessionToken, events);
            } else {
                sendEventToApiWithoutAuth(trackSessionToken, events);
            }
        }

        window.location.href = EXTENSION_UNINSTALL_REDIRECT_URL;
    }, [])

    return (<></>);
}

export default ExtensionUninstallationGoodbyePage;