import * as React from 'react';
import * as yup from 'yup';
import {useFormik} from "formik";

import {KeycloakTokenParsed} from "keycloak-js";
import axios from "axios";
import {CE_API_BASE_URL, CE_API_KEYCLOAK_USER_ROUTE, CE_API_USERS_ROUTE} from "../../utils/constants";
import {
    Button,
    Divider, IconButton, InputAdornment, Table, TableBody, TableCell,
    tableCellClasses,
    TableContainer, TableHead, TableRow,
    TextField,
    Typography,
    Grid
} from "@mui/material";
import {useKeycloak} from "@react-keycloak/web";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useState} from "react";

type ParsedToken = KeycloakTokenParsed & {
    email?: string

    preferred_username?: string

    given_name?: string

    family_name?: string

    sub?: string

    language?: string
}

const validationSchema = yup.object({
    password: yup
        .string()
        .min(6, 'Password should be of minimum 6 characters length')
        .required('Password is required'),
    passwordConfirmation: yup.string()
        .required('Confirming password is required')
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const KeycloakUserPasswordBlock: React.FC = () => {

    const { keycloak } = useKeycloak();
    const parsedToken: ParsedToken | undefined = keycloak?.tokenParsed;
    const userId = parsedToken?.sub
    const accessToken =keycloak?.token;
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const formik = useFormik({
        initialValues: {
            password: '',
            passwordConfirmation: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values,{resetForm}) => {
            axios.put(
                CE_API_BASE_URL + '/' + CE_API_USERS_ROUTE + '/' + CE_API_KEYCLOAK_USER_ROUTE + '/' + userId + '/pwd',
                JSON.stringify({password: values?.passwordConfirmation}),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        accept: 'application/json',
                        // Authorization: `Bearer ${accessToken}`,
                    },
                    timeout: 10000,
                    withCredentials: true
                }
            )
        .then(r => {
                // if (r?.data == true) {
                    alert("Change password successfully");
                    resetForm();
                // }
            }).catch(ex => {
                let error;
                if (ex?.code === "ECONNABORTED") {
                    error = "A timeout has occurred";
                } else if (ex?.response?.status === 200) {
                    alert("Success to change your password!");
                } else if (ex?.response?.status === 404) {
                    error = "Page not found. Maybe the route is wrong";
                } else if (ex?.response?.status === 403) {
                    error = "Unauthorized! Please login first or again";
                } else {
                    error = ex;
                }
                console.log(error);
                alert(error);
            })
        },
    });

    return (
        <form onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur}>
            
            <Grid container spacing={2}>
                <Grid item xs={12}>
                            <Typography variant="h6" noWrap component="div" style={{ fontFamily: 'Raleway'}} className="setting-subtitle" >
                                Change Password
                            </Typography>
                            <Divider/>
                            <br/>
                </Grid>


                <Grid item xs={12} sm={12} md={6} lg={6}>
                        <label htmlFor="password" className="settings-label">New Password:</label>
                </Grid>
                    
                <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                                        fullWidth
                                        id="password"
                                        name="password"
                                        type={showPassword ? 'text' : 'password'}
                                        size="small"
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        error={formik.touched.password && Boolean(formik.errors.password)}
                                        helperText={formik.touched.password && formik.errors.password}
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>,
                                        }}
                                    />
                        
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <label htmlFor="passwordConfirmation" className="settings-label">Confirm Password:</label>
                    </Grid>
                    
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                                    fullWidth
                                    id="passwordConfirmation"
                                    name="passwordConfirmation"
                                    type={showPassword ? 'text' : 'password'}
                                    size="small"
                                    value={formik.values.passwordConfirmation}
                                    onChange={formik.handleChange}
                                    error={formik.touched.passwordConfirmation && Boolean(formik.errors.passwordConfirmation)}
                                    helperText={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>,
                                    }}
                                />
                    </Grid>


                    <Grid item xs={12}>
                            <Button id="pwdChangeButton" variant="contained" type="submit" style={{textTransform:'none'}}>
                                    Update
                            </Button>
                    </Grid>  
            </Grid>
        </form>
    );
}
export default KeycloakUserPasswordBlock;