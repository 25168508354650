import React, {useEffect, useState} from "react";
import {GenericOnboardComponentProps} from "./WelcomePage";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useKeycloak} from "@react-keycloak/web";
import {useAppSelector, useAxios} from "../../utils/hooks";
import {
    CE_API_BASE_URL, INTENTION_KEY_NAME,
    ORDER_SOURCE_KEY_NAME, PERSONA_KEY_NAME,
    ROISOURCE_KEY_NAME,
    STRIPE_SESSION_COOKIE_NAME, SUBSCRIPTION_DURATION_KEY_NAME
} from "../../utils/constants";
import {useCookies} from "react-cookie";

type OnboardPricingPlanProps = GenericOnboardComponentProps & {
    purchaseSuccessUrl: string,
    purchaseFailureUrl: string
}
const OnboardPricingPlan: React.FC<OnboardPricingPlanProps> = (props) => {
    const { continueButtonHandler, purchaseSuccessUrl, purchaseFailureUrl } = props;
    const { keycloak } = useKeycloak();
    const axios = useAxios(CE_API_BASE_URL, keycloak);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [, setCookie] = useCookies(
        [STRIPE_SESSION_COOKIE_NAME,
            ROISOURCE_KEY_NAME,
            ORDER_SOURCE_KEY_NAME,
            PERSONA_KEY_NAME,
            INTENTION_KEY_NAME]);

    // parse the query parameters from the current URL
    const [searchParams] = useSearchParams();
    const subscriptionDuration = searchParams.get(SUBSCRIPTION_DURATION_KEY_NAME);
    const rioSource = searchParams.get(ROISOURCE_KEY_NAME);
    const orderSource = searchParams.get(ORDER_SOURCE_KEY_NAME);
    const persona = searchParams.get(PERSONA_KEY_NAME);
    const intention = searchParams.get(INTENTION_KEY_NAME);

    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    useEffect(() => {
        setCookie(ROISOURCE_KEY_NAME, rioSource);
        setCookie(ORDER_SOURCE_KEY_NAME, orderSource);
        setCookie(PERSONA_KEY_NAME, persona);
        setCookie(INTENTION_KEY_NAME, intention);

        if (!!subscriptionDuration
            && (subscriptionDuration === 'monthly'
                || subscriptionDuration === 'yearly')) {
            startPurchasing(subscriptionDuration);
        } else {
            document.body.className = "ce-background";
        }

        return () => {
            document.body.className = "";
        }
    }, [searchParams, subscriptionDuration]);

    const startPurchasing = (duration: string) => {
        duration = duration.toLocaleUpperCase();

        if (duration === "MONTHLY" || duration === "YEARLY") {
            axios.current?.post(
                "/purchase/stripe/create-checkout-session",
                JSON.stringify({
                    subscriptionDuration: duration,
                    purchaseSuccessUrl: purchaseSuccessUrl,
                    purchaseFailureUrl: purchaseFailureUrl
                }))
                .then(response => {
                    const {sessionId, redirectUrl} = response?.data;
                    setCookie(STRIPE_SESSION_COOKIE_NAME, sessionId, {path: "/"});
                    window.location.href = redirectUrl;
                })
                .catch(e => {
                    setSnackbarMessage("Network error!");
                    setSnackbarOpen(true);
                });
        } else {
            setSnackbarMessage("The plan you chose is no longer supported any more! Please select other plans.");
            setSnackbarOpen(true);
        }
    }

    return (
        <>
            <h2 className="text-center">You can get even more out of your extension:</h2>

            <div className="flex-row pricing-card-row">
                <div className="card flex-column">
                    <h4>FREE FOREVER</h4>
                    <h1 className="card--price">FREE</h1>
                    <p className="subtitle">FOREVER</p>

                    <button className="long-btn primary outline mt-1 mb-1" onClick={continueButtonHandler}>Continue for Free</button>

                    <p><b>Included in Your Plan:</b></p>
                    <p>Best-in-Class Writing Advice</p>
                    <p>ESL Writing Support</p>
                    <p>Advanced Word Suggestion Tech</p>

                    <a href="#features"><p>And More <i className="down"></i></p></a>
                </div>

                <div className="card flex-column">
                    <h4>PROFESSIONAL</h4>
                    <h1 className="card--price">$24</h1>
                    <p className="subtitle">MONTHLY</p>

                    <button
                        className="long-btn primary mt-1 mb-1"
                        onClick={()=>startPurchasing('MONTHLY')}
                    >
                        Buy Plan
                    </button>

                    <p><b>Included in Your Plan:</b></p>
                    <p>Everything in Free, plus:</p>
                    <p>Plagiarism Scanning</p>
                    <p>Powerful Research Tools</p>

                    <a href="#features"><p><strong>And More <i className="down"></i></strong></p></a>
                </div>

                <div className="card flex-column">
                    <h4>PRO SAVER</h4>
                    <h1 className="card--price">
                        <span className="discount-slash">$99</span>
                        $80</h1>
                    <p className="subtitle">YEARLY <span className="alert">Limited Time Offer!</span></p>

                    <button
                        className="long-btn primary mt-1 mb-1"
                        onClick={()=>startPurchasing('YEARLY')}
                    >
                        Buy Plan
                    </button>

                    <p><b>Included in Your Plan:</b></p>
                    <p>Everything in Free and Pro, plus: </p>
                    <p>A one-time payment at a greatly reduced cost!</p>

                    <a href="#features"><p><strong>And More <i className="down"></i></strong></p></a>
                </div>
            </div>

            <div id="features" className="card pricing-table">

                <div className="pricing-table--body">

                    <div className="features-tile">
                        <div className="pricing-table--header flex-column">
                            <div className="flex-column">
                                <h2>Features</h2>
                            </div>
                        </div>

                        <div className="pricing-data"><p>Unlimited word count for grammar checks</p></div>
                        <div className="pricing-data"><p>Web-based rich-text editor with formatting tools</p></div>
                        <div className="pricing-data"><p>Advanced writing rules and feedback for mechanics, grammar, and language usage</p>
                        </div>
                        <div className="pricing-data"><p>Multi-lingual feedback in seven languages</p>
                        </div>
                        <div className="pricing-data"><p>Style templates (e.g., MLA, APA,CMS,CBE)</p></div>
                        <div className="pricing-data"><p>Cite.ai to check originality of your document</p></div>
                        <div className="pricing-data"><p>Real-time collaboration and sharing tools </p>
                        </div>
                        <div className="pricing-data"><p>Deep AI writing analysis and stats </p></div>
                        <div className="pricing-data"><p>Lexipedia®, for building powerful vocabulary </p></div>
                        <div className="pricing-data"><p><span style={{textTransform:'none'}}>iseek.ai,</span>, advanced research tool</p></div>
                        <div className="pricing-data"><p>Subject and format-specific Writing Guides (e.g., general writing, business, academic, job search, technical)</p></div>
                    </div>

                    <div className="plan-tile">
                        <div className="pricing-table--header flex-column">
                            <div className="flex-column">
                                <h3>Free Forever</h3>
                                <p className="subtitle">Forever Free!</p>
                                <button className="primary outline">Get Started</button>
                            </div>
                        </div>

                        <div className="pricing-data"><p>Up to 250 Words*</p></div>
                        <div className="pricing-data"><i className="includes"></i></div>
                        <div className="pricing-data"><i className="includes"></i></div>
                        <div className="pricing-data"><i className="includes"></i></div>
                        <div className="pricing-data"><i className="excludes"></i></div>
                        <div className="pricing-data"><i className="excludes"></i></div>
                        <div className="pricing-data"><i className="excludes"></i></div>
                        <div className="pricing-data"><i className="excludes"></i></div>
                        <div className="pricing-data"><i className="excludes"></i></div>
                        <div className="pricing-data"><i className="excludes"></i></div>
                        <div className="pricing-data"><i className="excludes"></i></div>
                    </div>

                    <div className="plan-tile">
                        <div className="pricing-table--header flex-column">
                            <div className="flex-column">
                                <h3>Professional</h3>
                                <p className="subtitle">$24 per Month</p>
                                <button className="primary" onClick={()=>startPurchasing('MONTHLY')}>Buy Plan</button>
                            </div>
                        </div>

                        <div className="pricing-data"><i className="includes"></i></div>
                        <div className="pricing-data"><i className="includes"></i></div>
                        <div className="pricing-data"><i className="includes"></i></div>
                        <div className="pricing-data"><i className="includes"></i></div>
                        <div className="pricing-data"><i className="includes"></i></div>
                        <div className="pricing-data"><i className="includes"></i></div>
                        <div className="pricing-data"><i className="includes"></i></div>
                        <div className="pricing-data"><i className="includes"></i></div>
                        <div className="pricing-data"><i className="includes"></i></div>
                        <div className="pricing-data"><i className="includes"></i></div>
                        <div className="pricing-data"><i className="includes"></i></div>
                    </div>

                    <div className="plan-tile">
                        <div className="pricing-table--header flex-column">
                            <div className="flex-column">
                               
                                <h3>Pro. Saver</h3>
                                <p className="subtitle"><span className="discount-slash">$99</span> $80 per Year</p>
                                <button className="primary" onClick={()=>startPurchasing('YEARLY')}>Buy Plan</button>
                            </div>
                        </div>

                        <div className="pricing-data"><i className="includes"></i></div>
                        <div className="pricing-data"><i className="includes"></i></div>
                        <div className="pricing-data"><i className="includes"></i></div>
                        <div className="pricing-data"><i className="includes"></i></div>
                        <div className="pricing-data"><i className="includes"></i></div>
                        <div className="pricing-data"><i className="includes"></i></div>
                        <div className="pricing-data"><i className="includes"></i></div>
                        <div className="pricing-data"><i className="includes"></i></div>
                        <div className="pricing-data"><i className="includes"></i></div>
                        <div className="pricing-data"><i className="includes"></i></div>
                        <div className="pricing-data"><i className="includes"></i></div>
                    </div>
                </div>
                <p style={{fontSize: '.55em'}}>* per text field, document, or session.</p>
            </div>
        </>
    );
}

export default OnboardPricingPlan;