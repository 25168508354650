import * as React from 'react'
import {AppBar, Divider, Toolbar, Typography, IconButton, Button} from "@mui/material";
import smallLogo from './img/logo_ce-icon.png';
import middleLogo from './img/logo_ce-icon@2x.png';
import largeLogo from './img/logo_ce-icon@3x.png';
import MenuIcon from '@mui/icons-material/Menu';
import {useNavigate} from "react-router-dom";

type Props = {
    title?: string,
    handleDrawerToggle?: (e:  React.MouseEvent<HTMLButtonElement>) => void,
}
export const Header: React.FC<Props> = ({
                                            title = '',
                                            handleDrawerToggle
                                        }) => {
    const navigate = useNavigate();
    return (
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, 
        background: '#FFF', 
        boxShadow: '0px 1px 3px rgba(0, 0, 0, .1)', 
        maxHeight: '53px',
        }}>
            <Toolbar style={{minHeight: '53px', maxHeight: '53px'}}> 
                <Button onClick={()=>navigate("/")} style={{ backgroundColor: 'transparent'}} >
                    <Typography variant="h6" noWrap component="div">
                        <img src={smallLogo} alt={"CE logo"}
                             srcSet={`${smallLogo} 300w, ${middleLogo} 768w, ${largeLogo} 1280w`}
                             width="44px" style={{marginTop:"4px"}}
                        />
                    </Typography>
                </Button>
                <Divider orientation="vertical" flexItem sx={{ borderRightWidth: 1 }} style={{marginLeft:"1em", marginTop: '0.5em', marginBottom: '0.5em', background:'#CCC'}}/>
                <Typography variant="h6" noWrap component="div" style={{marginLeft:"1em", marginTop: '0.5em', marginBottom: '0.5em', color:'#2d7222'}}>
                    {title}
                </Typography>
                <div style={{flexGrow:'2', textAlign: 'right'}}>
                    <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' }, color: '#000'}}
                        >
                        <MenuIcon />
                    </IconButton>
                </div>
                   
            </Toolbar>
        </AppBar>
    );
}
