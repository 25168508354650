import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
    Box,
    Button,
    Card,
    Link as MatLink,
    Stack,
    styled,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography,
} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import { ReactComponent as LogoFull } from "../../../assets/ce-logo-full.svg";
import { ReactComponent as LanguageImage } from "../../../assets/icon.language.svg";
import { ReactComponent as ResumeImage } from "../../../assets/icon.resume.svg";
import { ReactComponent as WritersGuidesImage } from "../../../assets/icon.writers-guides.svg";
import { ReactComponent as WritingRulesImage } from "../../../assets/icon.writing-rules.svg";
import CELogo from "../../../assets/img.ce-logo.svg";
import TextAreaBackground from "../../../assets/img.demo-field-bg.svg";
import ChromeExtensionImg from "../../../assets/chrome.svg";
import FirefoxExtensionImg from "../../../assets/firefox.svg";
import EdgeExtensionImg from "../../../assets/edge.svg";
import SafariExtensionImg from "../../../assets/safari.svg";

import ProBenefit1 from "../../../assets/pro.wordCount.svg";
import ProBenefit2 from "../../../assets/pro.writingStats.svg";
import ProBenefit3 from "../../../assets/pro.sharing.svg";
import ProBenefit4 from "../../../assets/pro.lexipedia.svg";
import ProBenefit5 from "../../../assets/pro.iseek.svg";
import ProBenefit6 from "../../../assets/pro.citeai.svg";

import { PRODUCT_VERSION_KEY_NAME } from "../../../utils/constants";
import { GenericOnboardComponentProps } from "../WelcomePage";
import CarouselDescription from "./CarouselDescription";
import {browserName} from "react-device-detect";
import {PLAN_PAGE_ENDPOINT} from "../../../routes/endpointConstants";
import {checkIfExtensionCreatesDot} from "../../../utils/utils";
import {useKeycloak} from "@react-keycloak/web";
import useCeMetrics from "../../../metrics/useCeMetrics";
import {ParsedToken} from "../../../utils/types/keycloakExtraType";

const DemoTextArea = styled("div")({
    background: "none",
    // backgroundImage: `url(${TextAreaBackground})`,
    // backgroundRepeat: "no-repeat",
    // backgroundSize: "cover",
    // backgroundPosition: "padding-box",
    boxShadow: "7px 7px 0px #9BC83A, -7px -7px 0px #F7981D",
    borderRadius: "11px",
    padding: "10px 25px",
    paddingTop: "0",
    outline: "none",
    border: "3px solid #161616",
    width: "725px",
    height: "245px",
    resize: "none",
    overFlow: "scroll",
    boxSizing: "border-box",
    fontSize: "14",
    fontFamily: "'Times New Roman', serif",
    lineHeight: "1.5em",
    overflowY: "auto",
    autocorrect: "false"
});

const NextButton = styled(Button)({
    textTransform: "none",
    fontFamily: "'Raleway', sans-serif",
    fontSize: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
    maxHeight: "33px",
    color: "#FFF",
    backgroundColor: "#0B845C",
    "&:hover": {
        backgroundColor: "#0C7351",
    }
});

const ContinueButton = styled(Button)({
    textTransform: "none",
    fontFamily: "'Raleway', sans-serif",
    fontSize: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
    maxHeight: "33px",
    color: "#FFF",
    backgroundColor: "#005B92",
    "&:hover": {
        backgroundColor: "#00426B",
    },
});

const UpgradeButton = styled(Button)({
    textTransform: "none",
    fontFamily: "'Raleway', sans-serif",
    fontSize: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
    maxHeight: "33px",
    color: "#FFF",
    backgroundColor: "#D77601",
    "&:hover": {
        backgroundColor: "#BA6601",
    },
});

const H2 = styled("h2")({
    fontWeight: "normal",
    fontSize: "35px",
    textAlign: "center",
});

const FeatureCard = styled(Card)({
    width: "auto",
    minWidth: 150,
    maxWidth: 260,
    height: 337,
    display: "flex",
    justifyContent: "center",
    padding: "20px",
    boxSizing: "border-box",
    boxShadow: "0px 1px 22px #40535D40",
    borderRadius: "7px",
    textAlign: "center",
    flexBasis: "100%"
});

const hoverText = styled("p")({
    padding: "15px",
});

const FeatureTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: "#161616",
        boxShadow: "0px 1px 22px #40535D40",
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white,
    },
}));

const StepNumber = styled("span")({
    padding: "10px 15px",
    borderRadius: "90px",
    background: "linear-gradient(45deg, rgba(26,116,170,1) 0%, rgba(26,98,153,1) 100%)",
    height: "15px",
    width: "15px",
    textAlign: "center",
    fontWeight: "900",
    color: "#FFF",
    marginRight: "10px",
});

type Stepper = {
    header: React.ReactElement,
    image: React.ReactElement;
    description: React.ReactElement;
};

const features: {
    title: string;
    image: React.ReactElement;
    hoverText: string;
}[] = [
    {
        title: "Advanced Writing Rules help you gradually improve skills.",
        image: <WritingRulesImage />,
        hoverText:
            "Stay on-brand with style suggestions and add job-specific terms to your dictionary!",
    },
    {
        title: "Improve the impact of your personal statement, resume, and emails.",
        image: <ResumeImage />,
        hoverText:
            "CorrectEnglish helps you polish your professional writing!",
    },
    {
        title: "Grammar feedback supported in seven languages.",
        image: <LanguageImage />,
        hoverText:
            "You can get feedback in: English, French, German, Spanish, Chinese, Korean, and Japanese.",
    },
    // {
    //   title:
    //     "Get extra writing help on various types of writing in the Writer's Guides.",
    //   image: <WritersGuidesImage />,
    //   hoverText:
    //     "See examples, checklists, and videos to help you gain skills in writing specific types of work.",
    // },
];

const proBenefits: {
    image: string;
    title: string;
}[] = [
    {
        image: ProBenefit1,
        title: "Unlimited word count for grammar checks",
    },
    {
        image:  ProBenefit2,
        title: "Stats for deep analysis of quality",
    },
    {
        image: ProBenefit3,
        title: "Format and share with colleagues",
    },
    {
        image:  ProBenefit4,
        title: "Lexipedia for word relationships",
    },
    {
        image:  ProBenefit5,
        title: "iSEEK for research",
    },
    {
        image:  ProBenefit6,
        title: "cite.ai checks for plagiarism",
    },
];

const pinPicturesMap = {
    'Chrome': ChromeExtensionImg,
    'Firefox': FirefoxExtensionImg,
    'Edge': EdgeExtensionImg,
    'Safari': SafariExtensionImg
};

const OnboardExtensionStart: React.FC<GenericOnboardComponentProps> = (
    props
) => {
    const [grammarCheckEventSent, setGrammarCheckEventSent] = useState(false);
    const navigate = useNavigate();
    const { continueButtonHandler } = props;
    const [carouselStep, setCarouselStep] = useState(0);

    const [searchParams] = useSearchParams();
    const version = searchParams.get(PRODUCT_VERSION_KEY_NAME);

    const {keycloak} = useKeycloak();
    const parsedToken: ParsedToken | undefined = keycloak?.tokenParsed;
    const {readTrackSessionToken, sendEventToApiWithAuth} = useCeMetrics(keycloak);
    const trackSessionToken = readTrackSessionToken();

    const currentBrowser = browserName;
    let PinImageForBrowser: string;
    if (currentBrowser in pinPicturesMap) {
        PinImageForBrowser = pinPicturesMap[currentBrowser];
    } else {
        PinImageForBrowser = pinPicturesMap["Chrome"];
    }

    let inputRef = useRef(null);
    useEffect(() => {
        if (!!inputRef && !!inputRef.current) {
            // @ts-ignore
            inputRef.current.focus();
        }

        // send onboard event. send events separately, because not sure extension grammar check will happen.
        (typeof trackSessionToken === 'string') && sendEventToApiWithAuth(trackSessionToken, [{
            type: "ONBOARD",
            data: {
                username: parsedToken?.email,
                browser: currentBrowser
            }
        }]);

        const extDotElementDetectTimer = setInterval(() => {
            if (!grammarCheckEventSent && (typeof trackSessionToken === 'string') && checkIfExtensionCreatesDot()) {
                sendEventToApiWithAuth(trackSessionToken, [{
                    type: 'GRAMMAR_CHECK_ON_WELCOME_PAGE',
                    data: {
                        username: parsedToken?.email,
                        browser: currentBrowser
                    }
                }]);
                setGrammarCheckEventSent(true);
                clearInterval(extDotElementDetectTimer);
            }
        }, 1000);

        return () => {
            clearInterval(extDotElementDetectTimer);
        }
    }, [inputRef, grammarCheckEventSent]);

    // set up default image, if the browser name is missing and the default image is  chrome's
    if (!PinImageForBrowser) {
        PinImageForBrowser = ChromeExtensionImg;
    }

    const topBar = (
        <Stack
            direction={"row"}
            sx={{ marginX: "41px", marginY: "25px" }}
            component="nav"
        >
            <Link to="/">
                <LogoFull style={{ maxWidth: "199px", maxHeight: "33px" }} />
            </Link>
        </Stack>
    );

    const headline = (
        <H2>
            Thank you for installing the{" "}
            <span style={{ color: "#F7981D" }}>Correct</span>
            <span style={{ color: "#9BC83A" }}>English</span>
            <span
                style={{
                    color: "#9BC83A",
                    fontSize: "16px",
                    verticalAlign: "super",
                }}
            >
        &#174;
      </span>{" "}
            Web Extension!
        </H2>
    );

    const steps: Stepper[] = [
        {
            header: (
                <H2>
                    Thank you for installing the{" "}
                    <span style={{ color: "#F7981D" }}>Correct</span>
                    <span style={{ color: "#9BC83A" }}>English</span>
                    <span
                        style={{
                            color: "#9BC83A",
                            fontSize: "16px",
                            verticalAlign: "super",
                        }}
                    >
          &#174;
        </span>{" "}
                    Web Extension!
                </H2>
            ),
            image: (
                <DemoTextArea id="demo-input-box" ref={inputRef} contentEditable={true} autoCorrect={"false"}>
                    <p style={{marginBottom: 0}}><b>Welcome to CorrectEnglish!</b></p>
                    <p style={{margin: 0}}>
                    If correct spelling earns you a few extra points on your papers, proper grammar will make a whole world of difference. Writing eloquently is a sign that you have a good understanding of the English language, which make a positive impression on readers.
                    </p>

                    <p style={{margin: 0}}><b>Super simple - Just start writing!</b></p>
                    <p style={{margin: 0}}>
                    Just start typing! Yes, it's that eazy! CorrectEnglish instantly checks you grammar as you write. But even better, it offers explanations so that you can learn as you go!
                    </p>

                    <p style={{margin: 0}}><b>Easy mistakes, easily fixed!</b></p>
                    <p style={{margin: 0}}>
                    Each error will be highlighted by a colorful underline, which will tell you the type of error it found: green for style suggestions, red for spelling errors, and grammar errors will be displayed in blue. The dot in the bottom-right corner also tells you how many errors there are.
                    </p>

                    <p style={{margin: 0}}><b>Green, red, blue!</b></p>
                    <p style={{margin: 0}}>
                    Go ahead and click on the gren, red or blue underlined words. Notice the cards that pop up to display the error description, and suggested improvement! Practicing good grammar have never been easier. For example, you will be able to create metaphors and use other stylistic techniques that are easily understandable with proper grammar.
                    </p>

                    <p style={{margin: 0}}><b>But Wait! There's more?</b></p>
                    <p style={{margin: 0}}>
                    CorrectEnglish is more then just a grammar and spelling checker! Take a quick tour around. Locate the "Language" dropdown: CorrectEnglish provides targeted instruction in NiNE languages.
                    </p>

                    <p style={{margin: 0}}><b>What about the gray lines?</b></p>
                    <p style={{margin: 0}}>
                        This Free Forever edition provides grammar insight up to to 250 words per text. Upgrade to experience the full power of the CorrectEnglish writing mastery program. The CorrectEnglish premium edition provide a 24/7, dedicated writing environment with all the tools you need to improve your writing.
                    </p>
                    <p style={{margin: 0}}>
                        Happy Writin!
                    </p>
                </DemoTextArea>
            ),
            description: (
                <CarouselDescription

                    description={
                        <ul style={{ fontSize: "15px", textAlign: "left", listStyle: "none", lineHeight: "4em"}}>
                            <li><StepNumber>1</StepNumber> Hover over an underline.</li>
                            <li><StepNumber>2</StepNumber> View and apply suggestions.</li>
                            <li><StepNumber>3</StepNumber> Improve your writing!</li>
                            <li>Try it out on the text on the left.</li>
                        </ul>
                    }
                />
            ),
        },
        {   header: (
                <H2>
                    Open{" "}
                    <span style={{ color: "#F7981D" }}>Correct</span>
                    <span style={{ color: "#9BC83A" }}>English</span>
                    <span
                        style={{
                            color: "#9BC83A",
                            fontSize: "16px",
                            verticalAlign: "super",
                        }}
                    >
        &#174;
      </span>{" "}
                    on your browser.
                </H2>
            ),
            image: <img src={PinImageForBrowser} alt="Pin" />,
            description: (
                <CarouselDescription style={{display: "flex", flexGrow: "2", alignItems: "center"}}
                                     description="Your extension settings include language and site options!"
                />
            ),
        },
        {
            header: (
                <H2>
                    Advanced tool available only with{" "}
                    <span style={{ color: "#F7981D" }}>Correct</span>
                    <span style={{ color: "#9BC83A" }}>English</span>
                    <span
                        style={{
                            color: "#9BC83A",
                            fontSize: "16px",
                            verticalAlign: "super",
                        }}
                    >
              &#174;
            </span>{" "}
                    Pro!
                </H2>
            ),
            image: (<></>),
            description: (
                <CarouselDescription
                    // title={
                    //   version === "pro"
                    //       ? "Your Professional Plan Comes With Benefits!"
                    //       : "Upgrade To The Professional Plan."
                    // }
                    title={""}
                    description={
                        <>
                            <Stack
                                display="flex"
                                direction="row"
                                // justifyContent="space-around"
                                gap={6}
                                // flexWrap="wrap"
                            >
                                {proBenefits.map((feature, idx) => (
                                    <FeatureCard key={idx}>
                                        <Stack justifyContent="space-between" alignItems="center">
                                            <Typography
                                                textAlign="center"
                                                fontWeight="bold"
                                                component="h3"
                                            >
                                            </Typography>
                                            <Box margin="auto">
                                                <div><img src={feature.image} /></div>
                                                <div style={{marginTop: "18px"}}>{feature.title}</div>
                                            </Box>
                                        </Stack>
                                    </FeatureCard>
                                ))}
                            </Stack>

                        </>
                    }
                />
            ),
        },
    ];

    const lastStep = steps.length - 1;

    const carouselNextHandler = () => {
        setCarouselStep((currentStep) => {
            // if (currentStep === lastStep) return 0;
            return currentStep + 1;
        });
    };

    return (
        <Stack style={{ fontFamily: "'Raleway', sans-serif", background:'#fff'}}>
            {topBar}

            {/* Carousel section */}
            <Box component="section" display="flex" justifyContent="center">
                <Stack maxWidth="xl" mx="25px">
                    {steps[carouselStep].header}
                    <Stack
                        direction={{ xs: "column", md: "row" }}
                        alignItems={{ xs: "center", md: "initial" }}
                        spacing={{ xs: 2, md: 6 }}
                        marginX="auto"
                    >
                        <Box
                            sx={{
                                minHeight: { xs: "initial", md: "287px" },
                                marginTop: "auto",
                                marginBottom: "auto",
                                display: "flex",
                            }}
                        >
                            {steps[carouselStep]?.image}
                        </Box>
                        <Stack justifyContent="space-between">
                            {steps[carouselStep].description}
                            <Stack direction="row" justifyContent="space-between">
                                {carouselStep < lastStep
                                    ?
                                    <>
                                        <Stack direction="row" columnGap="20px">
                                            {steps.map((_, idx) => (
                                                <MatLink
                                                    key={idx}
                                                    component="button"
                                                    color="#40535E"
                                                    fontSize="16px"
                                                    sx={{ padding: "0 10px" }}
                                                    underline={carouselStep === idx ? "always" : "none"}
                                                    onClick={() => setCarouselStep(idx)}
                                                >
                                                    {idx + 1}
                                                </MatLink>
                                            ))}
                                        </Stack>
                                        <NextButton
                                            endIcon={<ArrowForwardIosIcon />}
                                            onClick={carouselNextHandler}
                                        >
                                            Next
                                        </NextButton>
                                    </>
                                    :
                                    <>
                                        <Stack direction="row" justifyContent="center" alignItems={"center"} sx={{ width: "100%"}} gap={6}>
                                            {version !== "pro" && (
                                                <UpgradeButton
                                                    onClick={()=>navigate(PLAN_PAGE_ENDPOINT)}
                                                    sx={{ mb: "18px", mt:"18px"}}
                                                >
                                                    Upgrade to Professional
                                                </UpgradeButton>
                                            )}


                                            {/*<Button*/}
                                            {/*    onClick={continueButtonHandler}*/}
                                            {/*    sx={{ mb: "18px" }}*/}
                                            {/*>*/}
                                            {/*  or Continue to My Account*/}
                                            {/*</Button>*/}
                                            <ContinueButton
                                                onClick={continueButtonHandler}
                                                sx={{ mb: "18px", mt:"18px"}}
                                            >
                                                Continue to My Account
                                            </ContinueButton>
                                        </Stack>
                                    </>
                                }
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Box>

            {/* Feature section */}
            <Box
                component="section"
                bgcolor={"hsla(160, 43%, 93%, 1)"}
                display="flex"
                justifyContent="center"
                position="relative"
                sx={{
                    overflowX: "hidden",
                    "&::after": {
                        content: "''",
                        position: "absolute",
                        top: "0",
                        width: "120%",
                        height: 100,
                        borderRadius: "50%",
                        transform: "translateY(-65%)",
                        background: "white",
                    },
                }}
            >
                <Stack maxWidth="lg" mx="25px" mb="45px" pt="30px">
                    <H2>Here's how CorrectEnglish will help you:</H2>
                    <Stack
                        direction="row"
                        justifyContent="space-around"
                        gap={6}
                        flexWrap="wrap"
                    >
                        {features.map((feature, idx) => (
                            <FeatureCard key={idx}>
                                <Stack justifyContent="space-between" alignItems="center">
                                    <Typography
                                        textAlign="center"
                                        fontWeight="bold"
                                        component="h3"
                                    >
                                        {feature.title}
                                    </Typography>
                                    <Box margin="auto">
                                        <FeatureTooltip
                                            title={
                                                <Typography fontWeight="bold">
                                                    {feature.hoverText}
                                                </Typography>
                                            }
                                            arrow
                                        >
                                            {feature.image}
                                        </FeatureTooltip>
                                    </Box>
                                </Stack>
                            </FeatureCard>
                        ))}
                    </Stack>
                </Stack>
            </Box>

            {/* Footer section */}
            {/* TODO comment out util it becomes true*/}
            {/*<Box*/}
            {/*    component="footer"*/}
            {/*    py="43px"*/}
            {/*    display="flex"*/}
            {/*    alignItems="center"*/}
            {/*    justifyContent="center"*/}
            {/*>*/}
            {/*    <Typography fontSize="15px" maxWidth="lg" mx="25px" textAlign="center">*/}
            {/*        We've sent you an email copy of these instructions for your records.*/}
            {/*        If you have any questions, please reach out to us at{" "}*/}
            {/*        <MatLink href="mailto:support@correctenglish.com" color="#005B92">*/}
            {/*            support@correctenglish.com*/}
            {/*        </MatLink>{" "}*/}
            {/*        .*/}
            {/*    </Typography>*/}
            {/*</Box>*/}
        </Stack>
    );
};

export default OnboardExtensionStart;
