import React, {useEffect} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useNavigate, useSearchParams} from "react-router-dom";
import {PRODUCT_PATH_PARAMETER_KEY_NAME} from "../../utils/constants";
import CommonErrorPage from "../CommonErrorPage";
import '../../assets/style.css';
import ExtensionFlowIndex from "./extensionFlow/ExtensionFlowIndex";
import WebFlowIndex from "./editorFlow/WebFlowIndex";
import {useBrowserExtensionStore} from "../../utils/hooks";
import {checkIfExtensionInstalled} from "../../utils/utils";
import {isDesktop, isSafari} from "react-device-detect";

export type GenericOnboardComponentProps = {
    continueButtonHandler: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    interruptHandler: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const WelcomePage: React.FC = () => {
    const {keycloak} = useKeycloak();
    const [searchParams] = useSearchParams();
    const prod = searchParams.get(PRODUCT_PATH_PARAMETER_KEY_NAME);
    const step = searchParams.get('s');
    const extensionStoreLink = useBrowserExtensionStore();
    const navigate = useNavigate();

    useEffect(() => {
        if (prod !== 'extension') {
            document.body.className = "ce-background";
        }

        (!isDesktop) && keycloak.register();

        return () => {
            document.body.className = "";
        }
    }, [prod]);

    if (!keycloak) {
        return <CommonErrorPage
            errorMessages={["Authentication service initialization Error"]}
        />;
    } else if (!!keycloak && !keycloak.authenticated) {
        keycloak.register();
        return <CommonErrorPage
            errorMessages={["Jumping to Registration Page..."]}
        />;
    }



    // TODO hasn't finished. Check if user has finished welcome flow
    const extensionInstalled = checkIfExtensionInstalled();
    if (!extensionInstalled && !!extensionStoreLink && !isSafari) {
        window.location.href = extensionStoreLink;
        return <></>;
        // TODO remove it after we support safari
    } else if (isSafari) {
        navigate("/");
    } else if (!isDesktop) {
        return <></>;
    }

    return(
        <>
        {
            prod === 'extension'
                ? <ExtensionFlowIndex startStep={!!step ? +step : 0} />
                : (
                    <div className="container">
                        <div className="header-box logo"></div>
                        <WebFlowIndex startStep={!!step ? +step : 0} />
                    </div>
                )
        }
        </>
    );
};
export default WelcomePage;