import {useKeycloak} from "@react-keycloak/web";

export const RequiredAuth = ({children}: { children: JSX.Element }) => {
    const { keycloak } = useKeycloak();

    if (keycloak  && keycloak.authenticated === false) {
        keycloak.login();
        return null;
    }

    return children;
}

export const RequiredReg = ({children}: { children: JSX.Element }) => {
    const { keycloak } = useKeycloak();

    if (keycloak  && keycloak.authenticated === false) {
        keycloak.register();
        return null;
    }

    return children;
}