import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import React, { useEffect, useRef, useState } from "react";
import { Layout } from "../Layout";

const cloudServicesConfig = {
  // tokenUrl:
  //   "https://93592.cke-cs.com/token/dev/PRLDEhMKVVDVca1gTlmEJMtAXhFrNfgXeQl3?limit=10",
  tokenUrl:
    "https://93592.cke-cs.com/token/dev/PRLDEhMKVVDVca1gTlmEJMtAXhFrNfgXeQl3?user.id=e1&user.name=Tom Rowling&user.avatar=https://randomuser.me/api/portraits/men/30.jpg&role=writer",
  webSocketUrl: "wss://93592.cke-cs.com/ws",
  uploadUrl: "https://93592.cke-cs.com/easyimage/upload/",
  channelId: "1lf09pm1vks",
};

const editorConfiguration = {
  toolbar: [
    "heading",
    "|",
    "fontsize",
    "fontfamily",
    "|",
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "removeFormat",
    "highlight",
    "|",
    "alignment",
    "|",
    "numberedList",
    "bulletedList",
    "|",
    "undo",
    "redo",
    "|",
    "comment",
    "trackChanges",
    "|",
    "ckbox",
    "imageUpload",
    "link",
    "blockquote",
    "insertTable",
    "mediaEmbed",
  ],
  cloudServices: {
    tokenUrl: cloudServicesConfig.tokenUrl,
    webSocketUrl: cloudServicesConfig.webSocketUrl,
  },
  collaboration: {
    channelId: cloudServicesConfig.channelId,
  },
  ckbox: {
    tokenUrl: cloudServicesConfig.tokenUrl,
  },
};

const EditorPage = () => {
  // const editorRef = useRef<{ CKEditor: any; ClassicEditor: any }>();
  // const { CKEditor, ClassicEditor } = editorRef.current || {};

  const [editorLoaded, setEditorLoaded] = useState(false);

  useEffect(() => {
    // editorRef.current = {
    //   CKEditor: require("@ckeditor/ckeditor5-react").CKEditor,
    //   ClassicEditor: require("@ckeditor/ckeditor5-build-classic"),
    // };
    setEditorLoaded(true);
  }, []);

  return (
    <Layout title="Editor">
      {editorLoaded && (
        <>
          <div>
            <h2>Using CKEditor 5 from online builder in React</h2>
          </div>
          <CKEditor
            editor={Editor}
            config={editorConfiguration}
            data="<p>Hello from CKEditor 5!</p>"
            onReady={(editor: any) => {
              // You can store the "editor" and use when it is needed.
              console.log("Editor is ready to use!", editor);
            }}
            onChange={(event: any, editor: any) => {
              const data = editor.getData();
              console.log({ event, editor, data });
            }}
            onBlur={(event: any, editor: any) => {
              console.log("Blur.", editor);
            }}
            onFocus={(event: any, editor: any) => {
              console.log("Focus.", editor);
            }}
          />
        </>
      )}
    </Layout>
  );
};

export default EditorPage;
