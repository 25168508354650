import React, {useEffect} from "react";
import {
    CAMPAIGN_KEY_NAME,
    INTENTION_KEY_NAME, MARKETING_ATTRIBUTES_COOKIES_KEY_NAME,
    ORDER_SOURCE_KEY_NAME,
    PERSONA_KEY_NAME,
    REDIRECT_URL_KEY_NAME,
    ROISOURCE_KEY_NAME,
    SUBSCRIPTION_DURATION_KEY_NAME
} from "../utils/constants";
import {useCookies} from "react-cookie";
import {useNavigate} from "react-router-dom";
import {getCeSharedSubDomain} from "../utils/utils";
import useCeMetrics from "../metrics/useCeMetrics";
import {useKeycloak} from "@react-keycloak/web";

/**
 * A middle layer for performing some processes.
 * 1. Handles the jumping from marketing site:
 *      parses query parameters, and saves them into cookies
 * 2. redirect users to the endpoint parsed from query parameter
 *      without most query parameters
 * */
const MiddleHandler: React.FC = () => {
    const navigate = useNavigate();
    const [,setCookies] = useCookies([MARKETING_ATTRIBUTES_COOKIES_KEY_NAME]);

    const searchParams = new URLSearchParams(document.location.search);
    const subscriptionDuration = searchParams.get(SUBSCRIPTION_DURATION_KEY_NAME);
    const redirectUrl = searchParams.get(REDIRECT_URL_KEY_NAME);

    const parsedDomain = getCeSharedSubDomain();
    const {keycloak} = useKeycloak();
    const {readTrackSessionToken, sendEventToApiWithAuth, sendEventToApiWithoutAuth} = useCeMetrics(keycloak);

    useEffect(() => {
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        let visitEvent = {type: "VISIT"};

        // set up visit event and store the query parameters into cookies
        if (Object.keys(searchParamsObject).length > 0) {
            visitEvent['data'] = searchParamsObject;
            setCookies(MARKETING_ATTRIBUTES_COOKIES_KEY_NAME,
                JSON.stringify(searchParamsObject),
                {domain: parsedDomain});
        }

        // send out the visit event to the backend
        const trackSessionToken = readTrackSessionToken();
        if (typeof trackSessionToken != 'undefined' && trackSessionToken != null) {
            if (keycloak?.authenticated) {
                sendEventToApiWithAuth(trackSessionToken, [visitEvent]);
            } else {
                sendEventToApiWithoutAuth(trackSessionToken, [visitEvent]);
            }
        }

        // redirect the user to the place specified in the parameters
        if (!!redirectUrl) {
            navigate(
                !!subscriptionDuration
                    ? redirectUrl + (window.location.search.includes('?') ? "&" : "?")
                        + SUBSCRIPTION_DURATION_KEY_NAME
                        + "=" + subscriptionDuration
                    : redirectUrl
            );
        }
    }, []);
    return <></>;
}

export default MiddleHandler;