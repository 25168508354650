import React from "react";
import {useKeycloak} from "@react-keycloak/web";
import StripePayment from "./stripe/StripePayment";

const PricingPage:React.FC = () => {
    const { keycloak } = useKeycloak();
    return (
        keycloak?.authenticated
            ? <StripePayment />
            : <></>
    );
}

export default PricingPage;