import React, {useEffect, useState} from "react";
import "../../../assets/style.css"
import {useKeycloak} from "@react-keycloak/web";
import {useAppSelector, useAxios} from "../../../utils/hooks";
import {useCookies} from "react-cookie";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress, Link as MatLink,
    Modal,
    Snackbar
} from "@mui/material";
import {useNavigate, useSearchParams} from "react-router-dom";
import {MONTHLY_PLAN, YEARLY_PLAN} from "../../../utils/isExtensionPremium";
import {
    CAMPAIGN_KEY_NAME,
    CE_API_BASE_URL,
    INTENTION_KEY_NAME,
    ORDER_SOURCE_KEY_NAME,
    PERSONA_KEY_NAME,
    ROISOURCE_KEY_NAME,
    STRIPE_SESSION_COOKIE_NAME,
    SUBSCRIPTION_DURATION_KEY_NAME
} from "../../../utils/constants";
import Gtag from "../../../metrics/outside/Gtag";

const ExtensionProductDisplay: React.FC = () => {
    const navigate = useNavigate();
    const { keycloak } = useKeycloak();
    const axios = useAxios(CE_API_BASE_URL, keycloak);
    const plan = useAppSelector(state => state.consumerSubscriptions.plan);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [, setCookie] = useCookies(
        [STRIPE_SESSION_COOKIE_NAME,
            ROISOURCE_KEY_NAME,
            ORDER_SOURCE_KEY_NAME,
            PERSONA_KEY_NAME,
            INTENTION_KEY_NAME,
            CAMPAIGN_KEY_NAME]);

    // parse the query parameters from the current URL
    const [searchParams] = useSearchParams();
    const subscriptionDuration = searchParams.get(SUBSCRIPTION_DURATION_KEY_NAME);
    const rioSource = searchParams.get(ROISOURCE_KEY_NAME);
    const orderSource = searchParams.get(ORDER_SOURCE_KEY_NAME);
    const persona = searchParams.get(PERSONA_KEY_NAME);
    const intention = searchParams.get(INTENTION_KEY_NAME);
    const campaign = searchParams.get(CAMPAIGN_KEY_NAME);
    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    // ---------------  set up for disabling the purchase service ----------
    // const [openPurchaseModal, setPurchaseModalOpen] = React.useState(false);
    // const handlePurchaseModalClickOpen = () => {
    //     setPurchaseModalOpen(true);
    // };
    // const handlePurchaseModalClose = () => {
    //     setPurchaseModalOpen(false);
    // };
    //---------------------------- end ----------------------------------------

    useEffect(() => {
        setCookie(ROISOURCE_KEY_NAME, rioSource);
        setCookie(ORDER_SOURCE_KEY_NAME, orderSource);
        setCookie(PERSONA_KEY_NAME, persona);
        setCookie(INTENTION_KEY_NAME, intention);
        setCookie(CAMPAIGN_KEY_NAME, campaign);

        if (subscriptionDuration === 'MONTHLY' || subscriptionDuration === 'YEARLY') {
            startPurchasing(subscriptionDuration);
        } else {
            document.body.className = "ce-background";
        }

        return () => {
            document.body.className = "";
        }
    }, [searchParams, subscriptionDuration]);

    const startPurchasing = (duration: string) => {
        let selectedItem;
        if (duration.toLowerCase() === 'monthly') {
            selectedItem = {
                item_id: 'CEX-1M',
                item_name: 'CorrectEnglish extension monthly plan',
                price: 24
            }
        } else if (duration.toLowerCase() === 'yearly') {
            selectedItem = {
                item_id: 'CEX-12M',
                item_name: 'CorrectEnglish extension yearly plan',
                price: 80
            }
        }

        if (typeof selectedItem !== 'undefined') {
            Gtag.recordEvent('event', 'select_item', selectedItem);
        }

        // handlePurchaseModalClickOpen();
        /* TODO bring it back, when purchase service is ready */
        duration = duration.toLocaleUpperCase();

        if (duration === "MONTHLY" || duration === "YEARLY") {
            axios.current?.post(
                "/purchase/stripe/create-checkout-session",
                JSON.stringify({subscriptionDuration: duration}))
                .then(response => {
                    const {sessionId, redirectUrl} = response?.data;
                    setCookie(STRIPE_SESSION_COOKIE_NAME, sessionId, {path: "/"});
                    window.location.href = redirectUrl;
                })
                .catch(e => {
                    setSnackbarMessage("Network error!");
                    setSnackbarOpen(true);
                });
        } else {
            setSnackbarMessage("The plan you chose is no longer supported any more! Please select other plans.");
            setSnackbarOpen(true);
        }
    }

    if (!!subscriptionDuration) {
        return (
            <>
                <LinearProgress color="warning" />
                {/*TODO remove the dialog when the purchase service is back*/}
                {/*<Dialog*/}
                {/*    open={openPurchaseModal}*/}
                {/*    onClose={handlePurchaseModalClose}*/}
                {/*    aria-labelledby="alert-dialog-title"*/}
                {/*    aria-describedby="alert-dialog-description"*/}
                {/*>*/}
                {/*    <DialogTitle id="alert-dialog-title">*/}
                {/*        {"Premium purchase service is coming soon"}*/}
                {/*    </DialogTitle>*/}
                {/*    <DialogContent>*/}
                {/*        <DialogContentText id="alert-dialog-description">*/}
                {/*            Please contact us via*/}
                {/*            <MatLink href="mailto:support@correctenglish.com" color="#005B92"> support@correctenglish.com </MatLink>*/}
                {/*            for experiencing the premium functionalities.*/}
                {/*        </DialogContentText>*/}
                {/*    </DialogContent>*/}
                {/*    <DialogActions>*/}
                {/*        <Button onClick={()=>navigate("/")} autoFocus>*/}
                {/*            Home*/}
                {/*        </Button>*/}
                {/*    </DialogActions>*/}
                {/*</Dialog>*/}
            </>);
    }

    return (
        <>
            {/* TODO Only for disabling purchasing service. Will remove it in the future.*/}
            {/*<Dialog*/}
            {/*    open={openPurchaseModal}*/}
            {/*    onClose={handlePurchaseModalClose}*/}
            {/*    aria-labelledby="alert-dialog-title"*/}
            {/*    aria-describedby="alert-dialog-description"*/}
            {/*>*/}
            {/*    <DialogTitle id="alert-dialog-title">*/}
            {/*        {"Premium purchase service is coming soon"}*/}
            {/*    </DialogTitle>*/}
            {/*    <DialogContent>*/}
            {/*        <DialogContentText id="alert-dialog-description">*/}
            {/*            Please contact us via*/}
            {/*            <MatLink href="mailto:support@correctenglish.com" color="#005B92"> support@correctenglish.com </MatLink>*/}
            {/*             for experiencing the premium functionalities.*/}
            {/*        </DialogContentText>*/}
            {/*    </DialogContent>*/}
            {/*    <DialogActions>*/}
            {/*        <Button onClick={handlePurchaseModalClose} autoFocus>*/}
            {/*            Close*/}
            {/*        </Button>*/}
            {/*    </DialogActions>*/}
            {/*</Dialog>*/}
            {/*---------------------------- end of modal ------------------------------*/}
            <button className="" style={{background: '#1A1A1A', color: '#FFF'}}
                    tabIndex={0}
                    type="button"
                    onClick={()=>navigate("/")}
            >
                <i className="home"></i> Home
            </button>
            <div className="header-box">
                <div className="logo"></div>
            </div>
            <div className="container">

                <h1 className="text-center">Choose Your Subscription:</h1>

                <div className="flex-row pricing-card-row">
                    <div className="card plan-card flex-column">
                        <h4>FREE FOREVER</h4>
                        <h1 className="plan-card--price">FREE</h1>
                        <p className="subtitle">FOREVER</p>

                        <button
                            className="long-btn primary outline mt-1 mb-1"
                            disabled={ !plan
                                || plan?.toLowerCase().includes(MONTHLY_PLAN.toLowerCase())
                                || plan?.toLowerCase().includes(YEARLY_PLAN.toLowerCase())}
                            onClick={()=>navigate('/')}>Continue for Free</button>

                        <p><b>Included in Your Plan:</b></p>
                        <ul>
                            <li>Best-in-Class Writing Advice</li>
                            <li>ESL Writing Support</li>
                            <li>Advanced Word Suggestion Tech</li>
                            <li className="mobile">Easily Apply APA, MLA and Other Formats with a Single Click</li>
                        </ul>

                        <a className="hidden-mobile" href="#features"><p><b>And More</b> <i className="down"></i></p>
                        </a>
                    </div>

                    <div className="card plan-card flex-column">
                        <h4>PROFESSIONAL</h4>
                        <h1 className="plan-card--price">$24</h1>
                        <p className="subtitle">MONTHLY</p>

                        <button
                            className="long-btn primary mt-1 mb-1"
                            disabled={plan?.toLowerCase().includes(MONTHLY_PLAN.toLowerCase())  || !plan}
                            onClick={()=>startPurchasing('MONTHLY')}
                        >
                            {plan?.toLowerCase() === MONTHLY_PLAN.toLowerCase() ? 'Current Plan' : 'Buy Plan'}
                        </button>

                        <p><b>Included in Your Plan:</b></p>
                        <p>Everything in Free, plus:</p>
                        <ul>
                           
                            <li className="hidden-mobile">Plagiarism Scanning</li>
                            <li className="hidden-mobile">Powerful Research Tools</li>
                            <li className="mobile">Access to dozens of writing templates</li>
                            <li className="mobile">Full Access To <span style={{textTransform:"none"}}>iseek.ai,</span> The Most Advanced Research Tool Available</li>
                            <li className="mobile">Full Access To Lexipedia, The Most Powerful Word Relationship Tool</li>
                            <li className="mobile">AcceBest-In-Class Originality & Plagiarism Check</li>
                        </ul>

                        <a className="hidden-mobile" href="#features"><p><b>And More</b> <i className="down"></i></p></a>
                    </div>

                    <div className="card plan-card flex-column">
                        <h4>PRO SAVER</h4>
                        <h1 className="plan-card--price">
                             {/*<span className="discount-slash">$99</span> */}
                            $80</h1>
                 

                        <button
                            className="long-btn primary mt-1 mb-1"
                            disabled={plan?.toLowerCase().includes(YEARLY_PLAN.toLowerCase()) || !plan}
                            onClick={()=>startPurchasing('YEARLY')}
                        >
                            {plan?.toLowerCase() === YEARLY_PLAN.toLowerCase() ? 'Current Plan' : 'Buy Plan'}
                        </button>

                        <p><b>Included in Your Plan:</b></p>
                        <p>Everything in Free and Pro, plus:</p>
                        <ul>
                            <li>A one-time payment at a greatly reduced cost!</li>
                        </ul>

                        <a className="hidden-mobile" href="#features"><p><b>And More</b> <i className="down"></i></p></a>
                    </div>
                </div>

                <div id="features" className="card pricing-table hidden-mobile">

                    <div className="pricing-table--body">

                        <div className="features-tile">
                            <div className="pricing-table--header flex-column">
                                <div className="flex-column">
                                    <h2>Features</h2>
                                </div>
                            </div>

                            <div className="pricing-data"><p>Unlimited word count for grammar checks</p></div>
                            <div className="pricing-data"><p>Web-based rich-text editor with formatting tools</p></div>
                            <div className="pricing-data"><p>Advanced writing rules and feedback for mechanics, grammar, and language usage</p></div>
                            <div className="pricing-data"><p>Multi-lingual feedback in seven languages</p></div>
                            <div className="pricing-data"><p>Style templates (e.g., MLA, APA,CMS,CBE)</p></div>
                            <div className="pricing-data"><p>Cite.ai, to check originality of your document</p></div>
                            <div className="pricing-data"><p>Real-time collaboration and sharing tools</p></div>
                            <div className="pricing-data"><p>Deep AI writing analysis and stats</p></div>   
                            <div className="pricing-data"><p>Lexipedia<sup>&reg;</sup>, the Powerful vocabulary-building Tool</p></div>
                            <div className="pricing-data"><p><span style={{textTransform:"none"}}>iseek.ai<sup>&reg;</sup>,</span> advanced research tool</p></div>
                            <div className="pricing-data"><p>Subject and format-specific Writing Guides (e.g., general writing, business, academic, job search, technical)</p></div>

                            <div className="pricing-data"><p>Save money with a yearly subscription</p></div>

                            <p className="pricing-data" style={{fontSize: ".75em"}}>* per text field, document, or session.</p>
                        </div>

                        <div className="plan-tile">
                            <div className="pricing-table--header flex-column">
                                <div className="flex-column">
                                    <h3>Free Forever</h3>
                                </div>
                            </div>

                            <div className="pricing-data"><p>Up to 250 Words*</p></div>
                            <div className="pricing-data"><i className="includes"></i></div>
                            <div className="pricing-data"><i className="includes"></i></div>
                            <div className="pricing-data"><i className="includes"></i></div>
                            <div className="pricing-data"><i className="excludes"></i></div>
                            <div className="pricing-data"><i className="excludes"></i></div>
                            <div className="pricing-data"><i className="excludes"></i></div>
                            <div className="pricing-data"><i className="excludes"></i></div>
                            <div className="pricing-data"><i className="excludes"></i></div>
                            <div className="pricing-data"><i className="excludes"></i></div>
                            <div className="pricing-data"><i className="excludes"></i></div>

                            <div className="pricing-data"><i className="excludes"></i></div>

                            <div id="continue--free" className="pricing-data">
                                <button
                                    className="long-btn primary outline mt-1 mb-1"
                                    disabled={ !plan
                                        || plan?.toLowerCase().includes(MONTHLY_PLAN.toLowerCase())
                                        || plan?.toLowerCase().includes(YEARLY_PLAN.toLowerCase())}
                                    onClick={()=>navigate('/')}
                                >Continue<br/>Free</button>
                            </div>
                        </div>

                        <div className="plan-tile">
                            <div className="pricing-table--header flex-column">
                                <div className="flex-column">
                                    <h3>Professional</h3>
                                </div>
                            </div>

                            <div className="pricing-data"><p><b>Unlimited</b></p></div>
                            <div className="pricing-data"><i className="includes"></i></div>
                            <div className="pricing-data"><i className="includes"></i></div>
                            <div className="pricing-data"><i className="includes"></i></div>
                            <div className="pricing-data"><i className="includes"></i></div>
                            <div className="pricing-data"><i className="includes"></i></div>
                            <div className="pricing-data"><i className="includes"></i></div>
                            <div className="pricing-data"><i className="includes"></i></div>
                            <div className="pricing-data"><i className="includes"></i></div>
                            <div className="pricing-data"><i className="includes"></i></div>
                            <div className="pricing-data"><i className="includes"></i></div>
                            <div className="pricing-data"><i className="excludes"></i></div>

                            <div id="buy--pro-monthly" className="pricing-data">
                                <a href="#"><button className="long-btn primary mt-1 mb-1">Buy CorrectEnglish<sup>&reg;</sup><br/>Professional</button></a>
                            </div>
                        </div>

                        <div className="plan-tile">
                            <div className="pricing-table--header flex-column">
                                <div className="flex-column">
                                    <h3>Pro. Saver</h3>
                                </div>
                            </div>

                            <div className="pricing-data"><p><b>Unlimited</b></p></div>
                            <div className="pricing-data"><i className="includes"></i></div>
                            <div className="pricing-data"><i className="includes"></i></div>
                            <div className="pricing-data"><i className="includes"></i></div>
                            <div className="pricing-data"><i className="includes"></i></div>
                            <div className="pricing-data"><i className="includes"></i></div>
                            <div className="pricing-data"><i className="includes"></i></div>
                            <div className="pricing-data"><i className="includes"></i></div>
                            <div className="pricing-data"><i className="includes"></i></div>
                            <div className="pricing-data"><i className="includes"></i></div>
                            <div className="pricing-data"><i className="includes"></i></div>
                            <div className="pricing-data"><i className="includes"></i></div>

                            <div id="buy--pro-yearly" className="pricing-data">
                                <a href="#"><button className="long-btn primary mt-1 mb-1">Buy CorrectEnglish<sup>&reg;</sup><br/>Pro Saver</button></a>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            {/*<div className="plan-ribbon text-center">*/}
            {/*    <p><b>Current Plan:</b> {plan}*/}
            {/*        /!*<a href="/">*!/*/}
            {/*        <button className="ml-2 secondary" onClick={()=>navigate("/")}>Go Back</button>*/}
            {/*        /!*</a>*!/*/}
            {/*    </p>*/}
            {/*</div>*/}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
            />
        </>
    );
};
export default ExtensionProductDisplay;