import React, {useState} from "react";
import {useAppDispatch, useAppSelector, useAxios, useCESubscription} from "../../utils/hooks";
import {
    Box, Button, Chip,
    CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    Typography
} from "@mui/material";
import {Subscription} from "../../utils/types/ecommerceType";
import {useKeycloak} from "@react-keycloak/web";
import {
    FREE_PLAN,
    MONTHLY_PLAN,
    parsePlanFromKeycloak,
    YEARLY_PLAN
} from "../../utils/isExtensionPremium";
import {dateCustomFormatter, isExtensionPlan, isExtensionPremiumPlan} from "../../utils/utils";
import {BRAND_NAME, CE_API_BASE_URL} from "../../utils/constants";
import {useNavigate} from "react-router-dom";
import {updateCurrentPlan} from "../../redux/slices/consumerSubscriptionSlice";
import CancelIcon from '@mui/icons-material/Cancel';
import {PLAN_PAGE_ENDPOINT} from "../../routes/endpointConstants";

const gridSize = 4;
export const SubscriptionDetail: React.FC<{subscription: Subscription | undefined | null, plan: string | undefined | null}> = (props ) => {
    const navigate = useNavigate();
    const {keycloak} = useKeycloak();
    const dispatch = useAppDispatch();
    const axios = useAxios(CE_API_BASE_URL ?? "", keycloak);
    const [loadingCircularProgress, setLoadingCircularProgress] = useState(false);
    const {updateUserSubscriptions} = useCESubscription();

    const plan = useAppSelector(state => state.consumerSubscriptions.plan);
    const {subscription} = props;
    // TODO in the future, when we integrate stripe info, we can change it
    const isFreePlan = plan?.toLowerCase() !== FREE_PLAN.toLowerCase();
    const paymentMethod = 'Visa ***********4242';
    const paid = true;
    const paymentResultMessage = paid ? "Paid" : "Unpaid";
    const paymentResultMessageStyleClassName = paid ? "setting-subtitle-green" : "setting-subtitle-red";
    const starDate = dateCustomFormatter(
        new Date(!!(subscription?.startDateTime)
            ? subscription?.startDateTime : ''));
    const nextPaymentDate = dateCustomFormatter(
        new Date(!!(subscription?.nextBillingPaymentDate)
            ? subscription?.nextBillingPaymentDate : ''));
    const externalSubscriptionCurrentPeriodEndDate = dateCustomFormatter(
        new Date(!!(subscription?.externalSubscriptionCurrentPeriodEndDate)
            ? subscription?.externalSubscriptionCurrentPeriodEndDate : ''));

    let price = 'Free';
    let currentPlan = '';
    if (!!plan && plan.toLowerCase() === MONTHLY_PLAN.toLowerCase()) {
        price = '$24';
        currentPlan = ' Premium - Monthly';
    } else if (!!plan && plan.toLowerCase() === YEARLY_PLAN.toLowerCase()) {
        price = '$80';
        currentPlan = ' Premium - Yearly';
    } else if (!plan || plan?.toLowerCase() === FREE_PLAN.toLowerCase()) {
        currentPlan = ' Free Forever'
    }

    const [openCancellationModal, setCancellationModalOpen] = React.useState(false);
    const handleCancellationModalClickOpen = () => {
        setCancellationModalOpen(true);
    };
    const handleCancellationModalClose = () => {
        setCancellationModalOpen(false);
    };

    const handleCancellation = () => {
        const subscriptionID = subscription?.id;
        if (subscriptionID === undefined) {
            alert("Some key information of subscription is missing, so we can't cancel for you. Please contact us!")
            return;
        }

        setCancellationModalOpen(false);
        setLoadingCircularProgress(true);
        axios?.current?.delete(
            "/subscription/cancel?subscriptionID=" + subscriptionID,
        ).then(r => {
            updateUserSubscriptions(() => {
                setLoadingCircularProgress(false);
            });
        }).catch(e => {
            setLoadingCircularProgress(false);
            alert("Failed to process subscription cancellation. Refresh and try again later.");
        })
    }

    return (
        <>
            <List style={{ fontFamily: 'Raleway'}}>
                <ListItem>
                    <Typography variant="h6" noWrap component="div" style={{ fontFamily: 'Raleway'}} className="setting-subtitle" >
                        {`${BRAND_NAME} - ${currentPlan}`}
                    </Typography>
                </ListItem>
                <ListItem>
                    <Grid item xs={6} sm={3}>
                        <Button
                            className="settings-page-button"
                            style={{textTransform:'none'}}
                            variant="contained"
                            color="warning"
                            onClick={() => navigate(PLAN_PAGE_ENDPOINT)}
                        >
                            Change
                        </Button>
                    </Grid>
                    <Grid item xs={6}  sm={5}>
                        {
                            subscription?.isAutoRenew === false
                                ? <div style={{color: 'red'}}>
                                    Subscription has been canceled. Premium access will continue thru {externalSubscriptionCurrentPeriodEndDate}
                                  </div>
                                : <Button
                                    className="settings-page-button"
                                    style={{textTransform:'none'}}
                                    variant="outlined"
                                    color="error"
                                    onClick={handleCancellationModalClickOpen}
                                    disabled={loadingCircularProgress || plan?.toLowerCase() === FREE_PLAN.toLowerCase()}
                                    endIcon={loadingCircularProgress && <CircularProgress size={24} color='success'/>}
                                  >
                                    Cancel Subscription
                                  </Button>
                        }
                        {/*<Button*/}
                        {/*    className="settings-page-button"*/}
                        {/*    style={{textTransform:'none'}}*/}
                        {/*    variant="outlined"*/}
                        {/*    color="error"*/}
                        {/*    onClick={() => handleClick(subscription?.id)}*/}
                        {/*    disabled={loadingCircularProgress || plan?.toLowerCase() === FREE_PLAN.toLowerCase()}*/}
                        {/*    endIcon={loadingCircularProgress && <CircularProgress size={24} color='success'/>}*/}
                        {/*>*/}
                        {/*    Cancel Subscription*/}
                        {/*</Button>*/}
                    </Grid>
                </ListItem>
                <ListItem>
                    <Grid item xs={gridSize} >
                        <ListItemText primary={
                            <label className="settings-label">Price:</label>}
                        />
                    </Grid>
                    <Grid item xs={gridSize} >
                        <ListItemText primary={
                            <label className="settings-label">{price}</label>}
                        />
                    </Grid>
                </ListItem>
                {/*<ListItem>*/}
                {/*    <Grid item xs={gridSize}>*/}
                {/*        <ListItemText primary={*/}
                {/*            <label className="settings-label">Start Date:</label>}*/}
                {/*        />*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={gridSize} style={{ display: "flex"}}>*/}
                {/*        <ListItemText primary={*/}
                {/*            <label className="settings-label">{starDate}</label>}*/}
                {/*        />*/}
                {/*        {*/}
                {/*            isFreePlan &&*/}
                {/*            <>*/}
                {/*                <ListItemText primary={*/}
                {/*                    <label className={paymentResultMessageStyleClassName}>{paymentResultMessage}</label>}*/}
                {/*                />*/}
                {/*            </>*/}
                {/*        }*/}
                {/*    </Grid>*/}
                {/*</ListItem>*/}
                {
                    isFreePlan &&
                    <>
                        <ListItem>
                            {
                                subscription?.isAutoRenew
                                ? <>
                                        <Grid item xs={gridSize}>
                                            <ListItemText primary={
                                                <label className="settings-label">Next Payment:</label>}
                                            />
                                        </Grid>
                                        <Grid item xs={gridSize}>
                                            <ListItemText primary={
                                                <label className="settings-label">{nextPaymentDate}</label>}
                                            />
                                        </Grid>
                                  </>
                                : <>
                                        <Grid item xs={gridSize}>
                                            <ListItemText primary={
                                                <label className="settings-label">Subscription Ends:</label>}
                                            />
                                        </Grid>
                                        <Grid item xs={gridSize}>
                                            <ListItemText primary={
                                                <label className="settings-label">{externalSubscriptionCurrentPeriodEndDate}</label>}
                                            />
                                        </Grid>
                                  </>
                            }
                        </ListItem>
                        {/*<ListItem alignItems="flex-start">*/}
                        {/*    <Grid item xs={gridSize}>*/}
                        {/*        <ListItemText primary={*/}
                        {/*            <label className="settings-label">Payment Method:</label>}*/}
                        {/*        />*/}
                        {/*    </Grid>*/}
                        {/*    <Grid item xs={gridSize} >*/}
                        {/*        <ListItemText*/}
                        {/*            primary={<label className="settings-label">{paymentMethod}</label>}*/}
                        {/*            secondary={*/}
                        {/*                <Typography*/}
                        {/*                    component="a"*/}
                        {/*                    href='javascript:void(0)'*/}
                        {/*                    onClick={()=>alert('You clicked it')}*/}
                        {/*                    variant="caption"*/}
                        {/*                    display="block">*/}
                        {/*                    Update Payment Method*/}
                        {/*                </Typography>}*/}
                        {/*        />*/}
                        {/*    </Grid>*/}
                        {/*</ListItem>*/}
                    </>
                }
                <Dialog
                    open={openCancellationModal}
                    onClose={handleCancellationModalClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Cancellation Confirm"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Do you really want to cancel your subscription? We will miss you!
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancellation} autoFocus>
                            Yes
                        </Button>
                        <Button onClick={() => handleCancellationModalClose()}>
                            Back
                        </Button>
                    </DialogActions>
                </Dialog>
            </List>
        </>
    );
}
const SubscriptionDetails: React.FC = () => {
    const subscriptions = useAppSelector(state => state.consumerSubscriptions.subs);
    const {keycloak} = useKeycloak();

    return (
        <>
            <Typography
                variant="h6"
                noWrap component="div"
                style={{ fontFamily: 'Raleway', marginBottom: '0.8em'}}
                className="setting-subtitle"
            >
                Subscription Details
            </Typography>
            <Divider/>
            {(()=>{
                if (Array.isArray(subscriptions)) {
                    if (subscriptions.length === 0 || subscriptions.every(ss => !isExtensionPlan(ss))) {
                        return <Box display="flex" justifyContent="center" marginTop={'1em'}><Chip icon={<CancelIcon />} label="Subscription Not Found" /></Box>
                    } else if (subscriptions.length > 0) {
                        const extensionSubs = subscriptions.filter(ss => isExtensionPlan(ss));
                        if (extensionSubs.length === 1 && !isExtensionPremiumPlan(extensionSubs[0])) {
                            return <SubscriptionDetail subscription={extensionSubs[0]} plan={parsePlanFromKeycloak(keycloak)} />;
                        }

                        return subscriptions
                            .filter(ss=>isExtensionPremiumPlan(ss))
                            .map(ss => <SubscriptionDetail key={ss.id} subscription={ss} plan={parsePlanFromKeycloak(keycloak)} />);
                    }
                } else if (subscriptions === null) {
                    return <Box display="flex" justifyContent="center"><Chip style={{marginTop: 2}} icon={<CancelIcon />} label="Error" /></Box>;
                } else {
                    return <Box display="flex" justifyContent="center"><CircularProgress sx={{color: 'success', position: 'relative'}}/></Box>
                }
            })()}
        </>
    );
};

export default SubscriptionDetails;