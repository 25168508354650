import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import {keycloak_ce_api_extension} from './keycloak_ce_api_extension';
import { AppRouter} from './routes';
import {loginNotificationToExt} from "./utils/loginNotificationToExt";
import {Provider} from "react-redux";
import {store} from "./redux/store";
import {CookiesProvider} from "react-cookie";
import {AuthClientTokens} from "@react-keycloak/core/lib/types";
import {sendEventToBackend} from "./utils/utils";
import {GOOGLE_TAG_URL} from "./utils/constants";
import Gtag from "./metrics/outside/Gtag";

const eventLogger = (event: unknown, error: unknown, ) => {
    console.log('onKeycloakEvent', event, error)
    if (event === 'onAuthSuccess') {

    }
}

const tokenLogger = (tokens: AuthClientTokens) => {
    const {token} = tokens;
    // if (typeof token !== 'undefined') {
    //     sendEventToBackend(token);
    // }
    typeof token !== 'undefined' && sendEventToBackend(token);
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

Gtag.initialized(GOOGLE_TAG_URL, 'G-188NG4FG1X')

root.render(
    <CookiesProvider>
        <Provider store={store}>
            <ReactKeycloakProvider
                authClient={keycloak_ce_api_extension}
                onEvent={eventLogger}
                onTokens={(tokens) => {
                    const {refreshToken} = tokens;
                    if (refreshToken) {
                        loginNotificationToExt(refreshToken);
                    }

                    tokenLogger(tokens);
                }}
            >
                <React.StrictMode>
                    <AppRouter />
                </React.StrictMode>
            </ReactKeycloakProvider>
        </Provider>
    </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
