import React, {useEffect} from "react";
import {
    FREE_PLAN,
    MONTHLY_PLAN,
    MONTHLY_PLAN_ROLE,
    YEARLY_PLAN,
    YEARLY_PLAN_ROLE
} from "../../../utils/isExtensionPremium";
import {useKeycloak} from "@react-keycloak/web";
import {useAppSelector} from "../../../utils/hooks";
import {useNavigate} from "react-router-dom";

export interface StripePaymentInfo {
    status?: 'complete' | 'expired' | 'open',
    paymentStatus?: 'paid' | 'unpaid' | 'no_payment_required',
    amountTotal?: number,
    pay_period?: string,
    billingAddressCollection?: string,
    customerEmail?: string,
    subscription?: string
}

const orderDetail = (plan: string) => {
    switch (plan?.toLowerCase()) {
        case MONTHLY_PLAN.toLowerCase():
            return <>CorrectEnglish Premium - Professional<br/><span>$9.99/Month</span></>;
        case YEARLY_PLAN.toLowerCase():
            return <>CorrectEnglish Premium - Pro Saver<br/><span>$80/Year</span></>;
    }
    return <>{plan}</>
}

const CeUpgradeConfirmationPage: React.FC<StripePaymentInfo | undefined | null> = (props) => {

    const {keycloak} = useKeycloak();
    const plan = useAppSelector(state => state.consumerSubscriptions.plan);
    const navigate = useNavigate();

    useEffect(() => {
        document.body.className = "ce-background";

        return () => {
            document.body.className = "";
        }
    }, [])

    return (
        keycloak?.authenticated ?

        <>  
         <div className="header-box" style={{marginBottom:"1em"}}>
                    <div className="logo"></div>
                </div>

            <div className="container">

                <h1 className="text-center" style={{marginBottom:"1em"}}>Thank You for your Purchase!</h1>
             

                <div className="card" style={{maxWidth: "640px", margin:'0 auto'}}>
                    <div>

                        <div>
                            <div className="text-center mb-2">
                              
                                <p><strong>Your payment was successful!</strong><br/>
                                    Check your email for your upcoming invoice, as well as instructions to activate your
                                    account.
                                </p>
                                <hr/>
                            </div>


                            <h3 className="text-center">
                                Your Order Details:
                            </h3>

                            <div className="flex-column">
                                <div className="order-details text-center" style={{margin: "0"}}>
                                    <h4 className="h3" style={{textAlign: "center", margin: "0", width: "100%", lineHeight: "2em", marginBottom: '1em'}}>
                                        {
                                            orderDetail(plan)
                                        }

                                    </h4>
                                   
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="text-center mt-3">
                                    <button className="primary long-btn" onClick={()=>navigate("/")}>Continue to CorrectEnglish {">>"}</button>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </>:<></>
    );
}

export default CeUpgradeConfirmationPage;