import * as React from 'react'

import { Header } from './header/Header'
import {Sidebar} from "./sidebar/Sidebar";
import {Box, CssBaseline, Toolbar} from '@mui/material';
import FontLoader from '../utils/fonts/FontLoader';
import font from '../utils/fonts/raleway/static/Raleway-Medium.ttf';
import {ReactNode} from "react";

type Props = {
  title?: string;
  children?: ReactNode
}

export const Layout: React.FC<Props> = ({
  children,
  title = '',
}) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  //Handle Menu menu opening and closing
  const handleMobileMenuClick = () => {
    setMobileOpen(!mobileOpen); 
  }

  return(
  <div>

    {/*<Header>*/}
    {/*  <title>{title}</title>*/}
    {/*  <meta charSet="utf-8" />*/}
    {/*  <meta name="viewport" content="initial-scale=1.0, width=device-width" />*/}
    {/*  <link*/}
    {/*    rel="stylesheet"*/}
    {/*    href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"*/}
    {/*  />*/}
    {/*</Header>*/}
    <Header title={title}/>
      <FontLoader fontFamily="Raleway" fontUrl={font} />
      <Box sx={{ display: 'flex' }}>

          <CssBaseline />
          <Header  handleDrawerToggle={handleMobileMenuClick} title={title}/>
          <Sidebar handleDrawerToggle={handleMobileMenuClick} mobileOpen={mobileOpen} />
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
              <Toolbar />
              <div className="container my-5">{children}</div>
          </Box>

          
      </Box>
  </div>
)}
