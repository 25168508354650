import Keycloak from 'keycloak-js';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
export const keycloak_ce_api_extension = Keycloak({
    url: !!(process.env.REACT_APP_KEYCLOAK_URL)
            ? process.env.REACT_APP_KEYCLOAK_URL
            : 'https://auth.correctenglish.com/auth',

    realm: !!(process.env.REACT_APP_KEYCLOAK_REALM)
            ? process.env.REACT_APP_KEYCLOAK_REALM
            : 'ce-api',

    clientId: !!(process.env.REACT_APP_KEYCLOAK_CLIENT_ID)
                ? process.env.REACT_APP_KEYCLOAK_CLIENT_ID
                : 'ce-extension',
});

