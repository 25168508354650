import {AppDispatch} from "../redux/store";
import Keycloak from "keycloak-js";
import {resetSubscriptionsState} from "../redux/slices/consumerSubscriptionSlice";
import {Subscription} from "./types/ecommerceType";
import {CE_API_BASE_URL, EX_FREE_FOREVER_ECOMMERCE_SUBTYPE_NAME, EX_PREMIUM_ECOMMERCE_SUBTYPE_NAME} from "./constants";
import {TRACK_SESSION_TOKEN_COOKIE_KEY_NAME} from "../metrics/constants";
import {Cookies} from "react-cookie";

export const logout = (dispatch: AppDispatch, keycloak: Keycloak.KeycloakInstance) => {
    dispatch(resetSubscriptionsState());
    keycloak.logout();
}

export const dateCustomFormatter = (date: Date | undefined | null): string | undefined | null => {
    if (!date || isNaN(date.getTime())) {
        return '--';
    }

    // British English uses day-month-year order; US English uses month-day-year order, and the design is the former one
    return new Intl.DateTimeFormat('en-GB', {day: "2-digit", month: "short", year: "numeric"}).format(date);
}

export const isExtensionPlan = (subscription: Subscription | undefined | null): boolean => {
    if (!subscription) {
        return false;
    }
    return subscription.productSubType?.name === EX_PREMIUM_ECOMMERCE_SUBTYPE_NAME
        || subscription.productSubType?.name === EX_FREE_FOREVER_ECOMMERCE_SUBTYPE_NAME;
}

export const isExtensionPremiumPlan = (subscription: Subscription | undefined | null): boolean => {
    if (!subscription) {
        return false;
    }
    return subscription.productSubType?.name === EX_PREMIUM_ECOMMERCE_SUBTYPE_NAME;
}

export const sendEventToBackend = async (kcToken?: string) => {
    const cookies = new Cookies();
    const TrackSessionToken = cookies.get(TRACK_SESSION_TOKEN_COOKIE_KEY_NAME);
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    await fetch(CE_API_BASE_URL + '/track/session/activity',{
        method: 'POST',
        credentials: "include",
        headers: {
            'Content-Type': 'application/json',
            'TrackSessionToken': TrackSessionToken,
            Authorization: `Bearer ${kcToken}`
        },
        body: JSON.stringify({
            source: {
                userAgent: navigator.userAgent,
                clientSite: "ce_hub",
                location: `{timezone: ${timeZone}}`
            },
            events: [
                {
                    type: 'LOGIN',
                    // data: JSON.stringify({
                    //
                    // })
                }
            ]
        })
    })
}

export const checkIfExtensionInstalled = (): boolean => {
    return !!(document?.getElementById('__ce_root'));
}

export const getCeSharedSubDomain = () => {
    const firstDotIndex = window.location.hostname.indexOf('.');
    return window.location.hostname.substring(firstDotIndex === -1 ? 0 : firstDotIndex + 1);
}

/**
 * If extension detects an input box, it creates the dot.
 * We can use it for determine if the extension performs grammar check
 * */
export const checkIfExtensionCreatesDot = () => {
    const elements = document?.getElementsByClassName('__ce-dot-root');
    return Object.keys(elements).length > 0
}

/**
 * Create a script tag under document node for introducing outside JS library.
 * */
export const AddJSLibraryWithLink = (link: string) => {
    const script = document.createElement('script');
    script.src = link;
    script.async = true;
    document.body.append(script);
}