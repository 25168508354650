import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {AxiosInstance} from "axios";
import {ParsedToken} from "../../utils/types/keycloakExtraType";
import {Subscription} from "../../utils/types/ecommerceType";
import {CE_API_BASE_URL} from "../../utils/constants";
import Keycloak from "keycloak-js";
import React from "react";
import {parsePlanFromKeycloak} from "../../utils/isExtensionPremium";

export interface ConsumerSubscriptionsState {
    subs: Subscription[] | null | undefined,
    status: 'fulfilled' | 'pending' | 'rejected',
    plan: string
}
const initialState: ConsumerSubscriptionsState = {
    subs: undefined,
    status: "pending",
    plan: '--'
}

export const checkSubForUser = createAsyncThunk(
    'consumerSubscriptions/checkSub',
    async (tools:{keycloak: Keycloak.KeycloakInstance, axios: React.MutableRefObject<AxiosInstance|undefined>}) => {
        const {keycloak, axios} = tools;
        const res = await axios.current?.post("/subscription");

        return res?.data;
    }
);

export const checkSubscriptions = createAsyncThunk(
    'consumerSubscriptions/checkSubs',
    async (keycloak: Keycloak.KeycloakInstance) => {
        const res = await fetch(
            CE_API_BASE_URL + '/subscription',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${keycloak?.token}`,
                },
                method: "POST",
                credentials: 'include'
            }
        );
        return res.json();
    }
);

export const mockCancelSubscriptionAsync = createAsyncThunk(
    'consumerSubscriptions/mockCancelSub',
    async (keycloak: Keycloak.KeycloakInstance) => {
        const parsedToken: ParsedToken | undefined = keycloak?.tokenParsed;
        const firstname = parsedToken?.given_name;
        const lastName = parsedToken?.family_name;
        const email = parsedToken?.email;
        const kcToken = keycloak?.token ?? '';
        console.log("kc:" + kcToken);
        fetch(
            CE_API_BASE_URL + "/subscription/mockSubscriptionExpiration",
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${keycloak?.token}`,
                },
                method: "POST",
                // timeout: 10000,
            }
        )
            .then(r => {
                // if (r?. === true) {
                    keycloak.updateToken(999999999999999999999999999999999999999999999999)
                        .then(r => {
                            if (r) {
                                return parsePlanFromKeycloak(keycloak);
                            }
                        });
                return parsePlanFromKeycloak(keycloak);
                // }
            });
        return parsePlanFromKeycloak(keycloak);
    }
);

export const consumerSubscriptionsSlice = createSlice({
    name: 'subscriptions',
    initialState,
    reducers: {
        updateSubscriptions: (state, action) => {
            state.status = 'fulfilled';
            state.subs = action.payload;
        },
        failedToCheckSubscriptions: (state) => {
            state.status = 'rejected';
            state.subs = null;
        },
        appendSubscriptions: (state, action) => {
            state.status = 'fulfilled';
            if (Array.isArray(state.subs)) {
                state.subs = state.subs.concat(action.payload);
            } else {
                state.subs = [action.payload];
            }
        },
        resetSubscriptionsState: (state) => {
            // state.subs = initialState.subs;
            // state.status = initialState.status;
            state = initialState;
        },
        updateCurrentPlan: (state, action) => {
            state.plan = action.payload;
        }
    },

    extraReducers: (builder) => {
        builder
            // .addCase(updateSubscriptionAsync.pending, (state) => {
            //     state.status = 'pending';
            // })
            // .addCase(updateSubscriptionAsync.fulfilled, (state, action) => {
            //     state.status = 'fulfilled';
            //     // state.subs = action.payload;
            // })
            // .addCase(updateSubscriptionAsync.rejected, (state) => {
            //     state.status = 'rejected';
            // })
            .addCase(mockCancelSubscriptionAsync.fulfilled, (state, action) => {
                state.status = "fulfilled";
                state.plan = action.payload;
            })
            .addCase(checkSubForUser.fulfilled, (state, action) => {
                state.status = "fulfilled";
                state.subs = action.payload;
            })
            .addCase(checkSubscriptions.rejected, (state, action) => {
                state.status = "rejected";
                state.subs = null;
            })
            .addCase(checkSubscriptions.fulfilled, (state, action) => {
                state.status = "fulfilled";
                state.subs = action.payload;
            });

    },
});

export const { updateSubscriptions, failedToCheckSubscriptions,appendSubscriptions,resetSubscriptionsState, updateCurrentPlan} = consumerSubscriptionsSlice.actions;
export default consumerSubscriptionsSlice.reducer;