import React, {useEffect} from "react";
import {Grid} from "@mui/material";

type Props = {
    errorMessages: string[]
}

const CommonErrorPage: React.FC<Props> = ({errorMessages}) => {
    useEffect(() => {
        document.body.className = "ce-background";

        return () => {
            document.body.className = "";
        }
    }, [])

    return (
            <Grid
                spacing={0}
                alignItems="center"
                style={{ minHeight: 'auto' }}
                container
                direction="column"
            >
            {
                Array.isArray(errorMessages) &&
                    errorMessages.map((errorMessage, index) =>
                        <Grid item xs={6} key={index}>
                            <p id={'err' + index}>{errorMessage}</p>
                        </Grid>
                    )
            }
            </Grid>
    );
}

export default CommonErrorPage;