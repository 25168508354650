import Keycloak from "keycloak-js";


export const MONTHLY_PLAN_ROLE = "ROLE_EXTENSION_PREMIUM_MONTHLY";
export const YEARLY_PLAN_ROLE = "ROLE_EXTENSION_PREMIUM_YEARLY";
export const MONTHLY_PLAN = "Monthly";
export const YEARLY_PLAN = "Yearly";
export const MONTHLY_YEARLY_PLAN = "Monthly & Yearly";
export const FREE_PLAN = "Free Forever";

export const hasRoleInKeycloak = (keycloak: Keycloak.KeycloakInstance, role:string): boolean => {
    const realm =  keycloak.hasRealmRole(role);
    const resource = keycloak.hasResourceRole(role);
    return realm || resource;
}

export const isExtensionPremium = (keycloak: Keycloak.KeycloakInstance, extensionPremiumRoles: string[]) => {
    if (keycloak && extensionPremiumRoles) {
        return extensionPremiumRoles.some(r => {
            return hasRoleInKeycloak(keycloak, r);
        });
    }
    return false;
}

export const parsePlanFromKeycloak = (keycloak: Keycloak.KeycloakInstance) => {
    let monthly = false;
    let yearly = false;

    if (!!keycloak) {
        monthly = hasRoleInKeycloak(keycloak, MONTHLY_PLAN_ROLE);
        yearly = hasRoleInKeycloak(keycloak, YEARLY_PLAN_ROLE);

        if (monthly && yearly) {
            return MONTHLY_YEARLY_PLAN;
        } else if (monthly) {
            return MONTHLY_PLAN;
        } else if (yearly){
            return YEARLY_PLAN;
        } else {
            return FREE_PLAN;
        }
    }
    return FREE_PLAN;
}