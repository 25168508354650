import {useCookies} from "react-cookie";
import {
    API_TRACK_SESSION_TOKEN_ENDPOINT,
    TRACK_EVENT_COOKIE_KEY_NAME,
    TRACK_SESSION_TOKEN_COOKIE_KEY_NAME
} from "./constants";
import {parseCeSharedDomain} from "./utils";
import {useAxios, useNonAuthAxios} from "../utils/hooks";
import Keycloak from "keycloak-js";
import {CE_API_BASE_URL} from "../utils/constants";

const useCeMetrics = (keycloak: Keycloak.KeycloakInstance | undefined) => {
    const parsedDomain = parseCeSharedDomain();

    const nonAuthAxios = useNonAuthAxios(CE_API_BASE_URL);
    const [cookies, setCookie, removeCookie] = useCookies([
        TRACK_SESSION_TOKEN_COOKIE_KEY_NAME,
        TRACK_EVENT_COOKIE_KEY_NAME]);
    const axios = useAxios(CE_API_BASE_URL, keycloak);

    const readTrackSessionToken = (): string | undefined => {
        const eventCookie =  cookies[TRACK_SESSION_TOKEN_COOKIE_KEY_NAME];
        return !!eventCookie ? eventCookie : undefined;
    }

    const setTrackSessionToken = (value: string) => {
        setCookie(TRACK_SESSION_TOKEN_COOKIE_KEY_NAME, value, {domain: parsedDomain});
    }

    const obtainNewTrackSessionTokenFromApi = async () => {
        try {
            const res = await nonAuthAxios.current?.get(API_TRACK_SESSION_TOKEN_ENDPOINT)
            return res?.data;
        } catch (e) {
         console.error('Cannot obtain new track session token: ' + e);
         return undefined;
        }
    }

    const sendEventToApiWithAuth = (trackSessionToken: string, events: object[], successCallback?: (r?)=>void) => {
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
        const timeZoneShort = new Date().toLocaleString('en-US', { timeZoneName: 'short' }).split(' ').pop();
        if (!!keycloak) {
            return axios.current?.post("/track/session/activity",
                {
                    source: {userAgent: window.navigator.userAgent, location: `{timezone: ${timeZone}, short: ${timeZoneShort}`},
                    events: events.map(e => {return {type: e['type'], data: JSON.stringify(e['data'])}})
                },
                {
                    headers: {
                        TrackSessionToken: trackSessionToken
                    }
                }).then(r => !!successCallback && successCallback(r));
        } else {
            console.log("Login required")
        }
    }

    const sendEventToApiWithoutAuth = (trackSessionToken: string, events: object[], successCallback?: (r?)=>void) => {
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
        const timeZoneShort = new Date().toLocaleString('en-US', { timeZoneName: 'short' }).split(' ').pop();
        return nonAuthAxios.current?.post("/track/visitor",
            {
                source: {"userAgent": window.navigator.userAgent, location: `{timezone: ${timeZone}, short: ${timeZoneShort}`},
                events: events.map(e => {
                    return {type: e['type'], data: JSON.stringify(e['data'])}
                })
            },
            {
                headers: {
                    TrackSessionToken: trackSessionToken
                }
            }).then(r => !!successCallback && successCallback(r));
    }

    const sendMarketingParamsToApiWithAuth = (events: object[], successCallback?: (r?)=>void) => {
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
        const timeZoneShort = new Date().toLocaleString('en-US', { timeZoneName: 'short' }).split(' ').pop();
        if (!!keycloak) {
            return axios.current?.post("/track/marketing",
                {
                    source: {userAgent: window.navigator.userAgent, location: `{timezone: ${timeZone}, short: ${timeZoneShort}`},
                    events: events.map(e => {return {type: e['type'], data: JSON.stringify({location: timeZone, ...e['data']})}})
                }).then(r => {
                    if (typeof successCallback === 'function') {
                        successCallback(r)
                    }
            });
        } else {
            console.error("Login required")
        }
    }

    const removeTrackSessionTokenCookies = () => {
        removeCookie(TRACK_SESSION_TOKEN_COOKIE_KEY_NAME);
    }

    const removeTrackEventCookies = () => {
        removeCookie(TRACK_EVENT_COOKIE_KEY_NAME);
    }

    const readTrackEventFromCookies = () => {
        const trackEvent = cookies[TRACK_EVENT_COOKIE_KEY_NAME];
        return (typeof trackEvent !== 'undefined') ? trackEvent : undefined;
    }

    return {readTrackSessionToken,
            setTrackSessionToken,
            obtainNewTrackSessionTokenFromApi,
            sendEventToApiWithAuth,
            sendEventToApiWithoutAuth,
            sendMarketingParamsToApiWithAuth,
            removeTrackSessionTokenCookies,
            removeTrackEventCookies,
            readTrackEventFromCookies};
}

export default useCeMetrics;

