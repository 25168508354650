import * as React from 'react';
import type {SidebarItemType} from "./SidebarItem";
import {SidebarItem} from "./SidebarItem"
import {Box, Button, Divider, Drawer, List, ListItem, Toolbar} from "@mui/material";
import './Sidebar.css';

import {useKeycloak} from "@react-keycloak/web";
import {
    CE_BLOG_URL,
    CE_FEEDBACK_URL,
    CE_USER_GUIDE,
    CLASSIC_CORRECTENGLISH_URL
} from "../../utils/constants";
import {logoutNotificationToExt} from "../../utils/logoutNotificationToExt";

import accountIconSvg from './sidebarIcons/icon.account.svg';
import logoutIconSvg from './sidebarIcons/icon.logout.svg';
import newDocIconSvg  from './sidebarIcons/icon.newdoc.svg';
import openLastDocIconSvg from './sidebarIcons/icon.open-last-doc.svg';
import userGuideIconSvg from './sidebarIcons/icon.guide.svg';
import ceBlogIconSvg from './sidebarIcons/icon.ce-blog.svg';
import homeIconSvg from './sidebarIcons/icon.home.svg';
import submitFeedbackIcon from './sidebarIcons/icon.submitfeedback.svg';
import {FREE_PLAN, isExtensionPremium, MONTHLY_PLAN_ROLE, YEARLY_PLAN_ROLE} from "../../utils/isExtensionPremium";
import {useAppSelector, useAppDispatch} from "../../utils/hooks";
import {ParsedToken} from "../../utils/types/keycloakExtraType";
import {logout} from "../../utils/utils";
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import {PLAN_PAGE_ENDPOINT, PRODUCTS_PAGE_ENDPOINT, SETTINGS_PAGE_ENDPOINT} from "../../routes/endpointConstants";
import {browserName, isMobile} from "react-device-detect";
import useCeMetrics from "../../metrics/useCeMetrics";

type Props = {
    handleDrawerToggle?: (e:  React.MouseEvent<HTMLButtonElement>) => void,
    mobileOpen?: boolean,
}

export type SidebarBlock = {
    name?: string,
    itemClassName?: string,
    sidebarItems?: Array<SidebarItemType>,
   
}

export const Sidebar: React.FC<Props> = ({
    handleDrawerToggle,
    mobileOpen
}) => {
    const { keycloak } = useKeycloak();
    const parsedToken: ParsedToken | undefined = keycloak?.tokenParsed;
    const {plan} = useAppSelector(state => state.consumerSubscriptions);
    const dispatch = useAppDispatch();
    const {sendEventToApiWithAuth, readTrackSessionToken} = useCeMetrics(keycloak);
    const trackSessionToken = readTrackSessionToken();

    
    const sidebarBlocks: Array<SidebarBlock> = [
        {
            itemClassName: 'menu-item',
            sidebarItems: [
                {
                    name: 'Home',
                    url: PRODUCTS_PAGE_ENDPOINT,
                    iconComponent: <img src={homeIconSvg} alt={'Home'}/>,
                }
            ]
        },
        {
            itemClassName: 'menu-item',
            sidebarItems: [
                {
                    name: 'My Account',
                    url: SETTINGS_PAGE_ENDPOINT,
                    subtitle: parsedToken?.email,
                    iconComponent: <img src={accountIconSvg} alt={'account'}/>,
                },
                ...(!(isExtensionPremium(keycloak,[MONTHLY_PLAN_ROLE, YEARLY_PLAN_ROLE])) && plan?.toLowerCase() === FREE_PLAN.toLowerCase()
                    ? [{
                            url: PLAN_PAGE_ENDPOINT,
                            iconComponent:
                                    <Button
                                        className="settings-page-button"
                                        style={{textTransform:'none', backgroundColor:'#196ea1'}}
                                        variant="contained"
                                        startIcon={<StarOutlineIcon />}
                                        color="warning">
                                        Upgrade to Premium
                                    </Button>
                        }] : []
                ),
            ]
        },
        ...(isMobile ? [] : [{
            name: 'Get Started',
            itemClassName: 'menu-item',
            sidebarItems: [
                {
                    name: 'Open Editor',
                    url: CLASSIC_CORRECTENGLISH_URL,
                    iconComponent: <img src={newDocIconSvg} alt={'newDoc'}/>,
                    sameTabForOutsideUrl: true,
                    performFunction: () => {
                        const event = [{
                            type: "LAUNCH_EDITOR",
                            data: {
                                username: keycloak?.authenticated ? parsedToken?.email : "non_logged_in",
                                browser: browserName,
                            }
                        }];
                        (typeof trackSessionToken === 'string') && sendEventToApiWithAuth(trackSessionToken, event);
                    }
                }
                // {
                //     name: 'Open Last Document',
                //     url: '',
                //     iconComponent: <img src={openLastDocIconSvg} alt={'lastDoc'}/>
                // },
                // {
                //     name: 'All Documents',
                //     url: '',
                //     iconComponent: <img src={openLastDocIconSvg} alt={'allDocs'}/>
                // }
            ]
        }]),
       
        {
            name: 'Support',
            itemClassName: 'menu-item menu-item--expand',
            sidebarItems: [
                {
                    name: 'User Guide',
                    url: CE_USER_GUIDE,
                    iconComponent: <img src={userGuideIconSvg} alt={'guide'}/>
                },
                {
                    name: 'CorrectEnglish Blog',
                    url: CE_BLOG_URL,
                    iconComponent: <img src={ceBlogIconSvg} alt={'CEBlog'}/>
                },
                {
                    name: 'Submit Feedback',
                    url: CE_FEEDBACK_URL,
                    iconComponent: <img src={submitFeedbackIcon} alt={'feedback'}/>
                }
            ]
        },

        {
            itemClassName: 'menu-item menu-item--bottom',
            sidebarItems: [
            
                {
                    name: 'Logout',
                    url: keycloak?.createLogoutUrl(),
                    iconComponent: <img src={logoutIconSvg} alt={'logout'} />,
                    performFunction: () => {logout(dispatch, keycloak); logoutNotificationToExt()},
                    sameTabForOutsideUrl: true
                }
            ]
               
        }
    ];

    const drawer = (
        <div style={{height: '100vh', display:'flex', flexDirection:'column'}}>
        <Toolbar />
        <Box sx={{ overflow: 'auto' }} style={{margin: '0.6em', display:'flex', flexDirection:'column', flexGrow: '1'}}>
            {/*<List>*/}
            {/*    <ListItem*/}
            {/*        sx={{fontFamily: 'Raleway'}}*/}
            {/*    >*/}
            {/*        <ListItemText*/}
            {/*            primary={*/}
            {/*                <Typography*/}
            {/*                    style={{ fontFamily: 'Raleway', fontWeight: 'bold', color: '#161616'}}*/}
            {/*                    variant="h6"*/}
            {/*                    noWrap*/}
            {/*                    component="div"*/}
            {/*                >*/}
            {/*                    Welcome, {parsedToken?.given_name}!*/}
            {/*                </Typography>*/}
            {/*            }*/}
            {/*            secondary={*/}
            {/*                <Typography*/}
            {/*                    style={{ fontFamily: 'Raleway', color: isExtensionPremium(keycloak, EXTENSION_PREMIUM_ROLES) ? '#fccb03' : '#5a5a5a'}}*/}
            {/*                    variant="subtitle2"*/}
            {/*                    noWrap*/}
            {/*                    component="div"*/}
            {/*                >*/}
            {/*                    {parsedToken?.email}*/}
            {/*                </Typography>}*/}
            {/*        />*/}
            {/*    </ListItem>*/}
            {/*</List>*/}
        {
            sidebarBlocks.map((sb, index) =>
                <List key={index} className={sb.itemClassName}>
                    {sb.name ? <ListItem><span className="menu-title">{sb.name}</span></ListItem> : <></>}
                    {index !== 0 ? <Divider/> : <></>}
                    {
                        sb.sidebarItems?.map((si, index) =>
                            <SidebarItem
                                key={index}
                                {...si}
                            />)
                    }
                </List>)
        }
        </Box>
        </div>
    );

    const drawerWidth = 250;
    return (
            <>
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box', boxShadow: '2.5px 1.5px 5px 0 rgba(0, 0, 0, 0.16)' },
                    }}
                >
                    {drawer}
                </Drawer>

                <Drawer
                variant="permanent"
                sx={{
                display: { xs: 'none', sm: 'block' },
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { height: '100vh', width: drawerWidth, boxSizing: 'border-box', boxShadow: '2.5px 1.5px 5px 0 rgba(0, 0, 0, 0.16)' },
                }}
                open
                >
                {drawer}
                </Drawer>
            </>
    )
}