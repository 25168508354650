import React from "react";
import chromeToCeImg from '../../../assets/chrome-to-ce.png';
import {GenericOnboardComponentProps} from "../WelcomePage";
import { browserName} from 'react-device-detect';
import {EXTENSION_CHROME_WEB_STORE_URL} from "../../../utils/constants";

const OnboardWebFinish: React.FC<GenericOnboardComponentProps> = (props) => {
    const { interruptHandler } = props;
    const BROWSER = browserName;
    return (
        <>
            <div id="features" className="card text-center">

                <h1>You're all set!</h1>
                <h5>We're excited to support your writing excellence!</h5>

                <div className="browser-graphic">
                    <img className="chrome_img" src={chromeToCeImg} width="410px" height="auto"  alt={'chrome-to-ce'}/>
                </div>

                <p><strong>Have you downloaded the CorrectEnglish web extension yet?</strong></p>

                <a href={EXTENSION_CHROME_WEB_STORE_URL}>
                    <button className="long-btn extra fullwidth mt-2">Download CorrectEnglish for <span
                        className="browser_name">{BROWSER}</span></button>
                </a>

                <button className="long-btn text-btn fullwidth mt-2" onClick={interruptHandler}>Maybe Later.</button>
            </div>
        </>
    );
}

export default OnboardWebFinish;