export const WELCOME_PAGE_ENDPOINT = '/welcome';
export const MIDDLE_HANDLER_ENDPOINT = '/handle';
export const HOME_PAGE_ENDPOINT = '/home';
export const SETTINGS_PAGE_ENDPOINT = '/settings';
export const PRODUCTS_PAGE_ENDPOINT = '/products';
export const PLAN_PAGE_ENDPOINT = '/plan';
export const PLAN_RESULT_PAGE_ENDPOINT = '/plan/result';
export const EDITOR_PAGE_ENDPOINT = '/editor';
export const DOCUMENTS_PAGE_ENDPOINT = '/documents';
export const EXTENSION_UNINSTALLATION_PAGE_ENDPOINT = '/goodbye';
