import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";
import OnboardPricingPlan from "../OnboardPricingPlan";
import OnboardExtensionFinish from "./OnboardExtensionFinish";
import OnboardExtensionStart from "./OnboardExtensionStart";
import useCeMetrics from "../../../metrics/useCeMetrics";
import {useKeycloak} from "@react-keycloak/web";
import {browserName} from "react-device-detect";
import {ParsedToken} from "../../../utils/types/keycloakExtraType";

type Props = {
    startStep: number
}
const ExtensionFlowIndex: React.FC<Props> = (props) => {
    const {startStep} = props;
    const [activeStep, setActiveStep] = React.useState(startStep);
    const navigate = useNavigate();
    const genericHandleInterrupt = () => navigate("/");
    const genericHandleContinue = () => setActiveStep(s => s + 1);
    const currentUrl = window.location.origin + '/welcome?p=extension';
    const {keycloak} = useKeycloak();
    const parsedToken: ParsedToken | undefined = keycloak?.tokenParsed;
    const {sendEventToApiWithAuth, sendEventToApiWithoutAuth, readTrackSessionToken} = useCeMetrics(keycloak);
    const currentBrowser = browserName;
    const trackSessionToken = readTrackSessionToken();
    const extensionInstallationEvent = {
        type: "EXTENSION_INSTALLATION",
        data: {
            username: parsedToken?.email,
            browser: currentBrowser
        }
    };

    useEffect(() => {
        if (typeof trackSessionToken === "string") {
            keycloak?.authenticated
                ? sendEventToApiWithAuth(trackSessionToken, [extensionInstallationEvent])
                : sendEventToApiWithoutAuth(trackSessionToken, [extensionInstallationEvent]);
        }
    }, []);

    return (
        <>
            {
                activeStep === 0 &&
                <OnboardExtensionStart
                    continueButtonHandler={genericHandleInterrupt}
                    interruptHandler={genericHandleInterrupt}
                />
            }

            {
                activeStep === 1 &&
                <OnboardPricingPlan
                    continueButtonHandler={genericHandleContinue}
                    interruptHandler={genericHandleInterrupt}
                    purchaseSuccessUrl={currentUrl + '&s=2'}
                    purchaseFailureUrl={currentUrl + '&s=1'}
                />
            }

            {
                activeStep === 2 &&
                <OnboardExtensionFinish
                    continueButtonHandler={genericHandleContinue}
                    interruptHandler={genericHandleInterrupt}
                />
            }
        </>
    );
}

export default ExtensionFlowIndex;