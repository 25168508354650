import * as React from 'react'
import {ReactElement} from "react";
import {EXTENSION_CHROME_WEB_STORE_URL} from "../utils/constants";

function CeExtButton(): ReactElement<any, any> | null {
    return !(document?.getElementById('__ce_root')) ? (
        <a  href={EXTENSION_CHROME_WEB_STORE_URL}>Get CorrectEnglish Extension</a>
    ) : (
        <span className="text-success">Ohh! You already installed CorrectEnglish extension</span>
    )
}

export default CeExtButton;
