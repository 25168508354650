import {useKeycloak} from "@react-keycloak/web";
import {Layout} from "../Layout";
import KeycloakUserPasswordBlock from "./KeycloakUserPasswordBlock";
import UserProfileBlock from "./UserProfileBlock";
import React from "react";
import {Button, Grid, Paper} from "@mui/material";
import "./Settings.css"
import SubscriptionDetails from "./SubscriptionDetails";
import {useNavigate} from "react-router-dom";
import {AccountDeletionBlock} from "./AccountDeletionBlock";

const SettingsPage:React.FC = () => {

    const { keycloak } = useKeycloak();
    const navigate = useNavigate();

    return (
        keycloak?.authenticated ?
        <Layout title={"My Account"}>
            {/*<Button onClick={()=>navigate("/welcome")}>Got to web welcome flow</Button>*/}
            {/*<Button onClick={()=>navigate("/welcome?p=extension")}>Got to extension welcome flow</Button>*/}
            {/*<Button onClick={()=>navigate("/welcome?p=extension&v=pro")}>Got to extension welcome flow Pro</Button>*/}
            {/*<Button onClick={()=>navigate("/welcome")}>Go to web welcome flow</Button>*/}
            {/*<Button onClick={()=>navigate("/welcome?p=extension")}>Go to extension welcome flow</Button>*/}
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                   
                        <Paper elevation={0} sx={{ width: '100%', bgcolor: 'background.paper', fontFamily: 'Raleway', padding: '1em', marginBottom: '1em'}}>
                            <UserProfileBlock />
                        </Paper>
                   
                    
                        <Paper elevation={0} sx={{ width: '100%', bgcolor: 'background.paper', fontFamily: 'Raleway', padding: '1em' , marginBottom: '1em'}}>
                            <KeycloakUserPasswordBlock />
                        </Paper>
                    
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Paper elevation={0} sx={{ width: '100%', bgcolor: 'background.paper', fontFamily: 'Raleway', padding: '1em', marginBottom: '1em'}}>
                        <SubscriptionDetails />
                    </Paper>

                    <Paper elevation={0} sx={{ width: '100%', bgcolor: 'background.paper', fontFamily: 'Raleway', padding: '1em', marginBottom: '1em'}}>
                        <AccountDeletionBlock />
                    </Paper>
                </Grid>
            </Grid>
        </Layout> : <></>
    );
}

export default SettingsPage;