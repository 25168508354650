import React, {useEffect, useState} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useAppDispatch, useAxios} from "../../../utils/hooks";
import {useCookies} from "react-cookie";
import CeUpgradeConfirmationPage, {StripePaymentInfo} from "./CeUpgradeConfirmationPage";
import {Button, Skeleton, Snackbar} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import {useNavigate} from "react-router-dom";
import {loginNotificationToExt} from "../../../utils/loginNotificationToExt";
import {
    CE_API_BASE_URL,
    INTENTION_KEY_NAME,
    ORDER_SOURCE_KEY_NAME,
    PERSONA_KEY_NAME,
    ROISOURCE_KEY_NAME,
    STRIPE_SESSION_COOKIE_NAME
} from "../../../utils/constants";
import {ParsedToken} from "../../../utils/types/keycloakExtraType";
import {updateCurrentPlan, updateSubscriptions} from "../../../redux/slices/consumerSubscriptionSlice";
import {parsePlanFromKeycloak} from "../../../utils/isExtensionPremium";

const StripePaymentResultPage: React.FC = () => {
    const {keycloak} = useKeycloak();
    const parsedToken: ParsedToken | undefined = keycloak?.tokenParsed;
    const axios = useAxios(CE_API_BASE_URL ?? "", keycloak);
    const [paymentResult, setPaymentResult] = useState<StripePaymentInfo | undefined | null>( null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [cookies, , removeCookies] = useCookies(
        [STRIPE_SESSION_COOKIE_NAME,
            ROISOURCE_KEY_NAME,
            ORDER_SOURCE_KEY_NAME,
            PERSONA_KEY_NAME,
            INTENTION_KEY_NAME]);
    const stripeSessionId  = cookies[STRIPE_SESSION_COOKIE_NAME];

    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleBackHomeClick = () => {
        axios?.current?.post(
            "/subscription"
        ).then(r => {
            dispatch(updateSubscriptions(r?.data));
            keycloak.updateToken(300)
                .then(refreshed => {
                    if (refreshed) {
                        dispatch(
                            updateCurrentPlan(
                                parsePlanFromKeycloak(keycloak)));
                    }
                });
        });
        navigate("/");
    }

    useEffect(() => {
        document.body.className = "ce-background";

        if (!stripeSessionId) {
            setSnackbarMessage("No payment need to be processed!");
            setSnackbarOpen(true);
        } else {
            axios.current?.post<StripePaymentInfo | undefined | null>(
                "/purchase/stripe/session/result?sessionId=" + stripeSessionId,
            JSON.stringify({
                username: parsedToken?.preferred_username,
                email: parsedToken?.email,
                firstName: !!(parsedToken?.given_name) ? parsedToken?.given_name : "firstName",
                lastName: !!(parsedToken?.family_name) ? parsedToken?.family_name : "lastName",
                orderSource: cookies[ORDER_SOURCE_KEY_NAME],
                roiSource: cookies[ROISOURCE_KEY_NAME],
                persona: cookies[PERSONA_KEY_NAME]})
            ).then(r => {
                setPaymentResult(r?.data);

                removeCookies(STRIPE_SESSION_COOKIE_NAME, {path: "/"});

                keycloak?.updateToken(300).then(r => {
                    dispatch(updateCurrentPlan(parsePlanFromKeycloak(keycloak)));
                    const {refreshToken} = keycloak;
                    if (!!refreshToken){
                        loginNotificationToExt(refreshToken);
                    }
                })
            }).catch(e => {
                console.log("Error occurred when finishing up purchase. " + e)
            });
    }

        return () => {
            document.body.className = "";
        }
    }, []);

    if (!!stripeSessionId && !paymentResult) {
        return (
            <>
                <div className="header-box text-center">
                    <h1>Finishing Your Payment, do not close the window...</h1>
                </div>

                <div className="card">
                    <Skeleton variant="text" animation={'wave'} width={400}/>
                    <Skeleton variant="text" animation={'wave'} width={400}/>
                    <div className="text-center mb-2">
                    </div>
                    <Skeleton variant="rectangular" animation={'wave'} height={118}/>

                    <Skeleton variant="text" animation={'wave'}/>
                </div>
            </>
        );
    }

    return (
        keycloak?.authenticated ?
            <div className="ce-container">
                <Button variant="contained" color="success" startIcon={<HomeIcon />} onClick={handleBackHomeClick}>
                    Home
                </Button>
                <CeUpgradeConfirmationPage {...paymentResult} />
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    message={snackbarMessage}
                />
            </div>
            :<></>
    );
};

export default StripePaymentResultPage;