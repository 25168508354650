import React, {useState} from "react";
import {
    Dialog,
    DialogContent,
    Slide
} from "@mui/material";
import "../../assets/style.css";
import {TransitionProps} from "@mui/material/transitions";
import {useAnnouncement} from "../../utils/hooks";

const slideItemArray = [
    {
        subTitle: <>&nbsp;</>,
        salutation: 'Dear CorrectEnglish Users,',
        content: (
            <>
                We are thrilled to announce exciting updates to CorrectEnglish!
                Our goal is to empower you to compose polished, precise, and
                professional messages for a variety of audiences and
                purposes.
                <br/>
                With CorrectEnglish, you access a powerful writing
                assistant that helps you write confidently and accurately. Whether
                you're working on a short email or a long report, the instant
                AI-powered feedback and writing guides will give you the support you
                need to write with ease.
                <br/>
                Click "What's New?" to learn what we've done to make your writing process
                even better!
            </>
        ),
        imgUrl:'https://correctenglish.com/images/modal/new-and-improved.png'
    },
    {
        subTitle: 'Advanced Spelling and Grammar Checking',
        // salutation: '',
        content: (
            <>
                CorrectEnglish utilizes the most accurate spelling and grammar checker on the market.
                We have enhanced our algorithms to provide even more accurate suggestions, ensuring your writing is error-free and professional.
            </>
        ),
        imgUrl:'https://correctenglish.com/images/modal/write-checking.png'
    },
    {
        subTitle: 'Writer\'s Guides',
        // salutation: '',
        content: (
            <>
                Whether you're composing an email, resume, cover letter, or professional document, our guides provide expert tips and step-by-step assistance to help you create a polished, well-structured document.
                Boost your confidence and writing skills with CorrectEnglish Writer's Guides.
            </>
        ),
        imgUrl:'https://correctenglish.com/images/modal/writer-guides.png'
    },
    {
        subTitle: 'Cite.ai',
        // salutation: '',
        content: (
            <>
                Introducing Cite.ai, your trusted tool for original and properly cited work.
                As a job seeker or professional, we understand the importance of plagiarism-free writing.
                Cite.ai scans billions of documents to ensure your content is authentic, credible, and meets academic standards.
                With Cite.ai, you can confidently showcase your originality and maintain the highest level of integrity in your writing.
            </>
        ),
        imgUrl:'https://correctenglish.com/images/modal/plagiarism-scan.png'
    },
    {
        subTitle: 'Writing Statistics',
        // salutation: '',
        content: (
            <>
                Enhance your natural writing skills with our In-depth Writing Analysis.
                Gain valuable insights into your writing quality, including word count, readability, and advanced AI evaluation of content development, language use, and organization.
                CorrectEnglish empowers you to elevate your writing to new heights with actionable data and feedback.
            </>
        ),
        imgUrl:'https://correctenglish.com/images/modal/writing-stats.png'
    },
    {
        subTitle: 'Accessibility',
        // salutation: '',
        content: (
            <>
                Write with confidence from anywhere using AI-powered writing assistance.
                Get feedback on your phone, tablet, or browser and elevate your writing skills wherever you write.
            </>
        ),
        imgUrl:'https://correctenglish.com/images/modal/accessible-everywhere.png'
    },
    {
        subTitle: <>&nbsp;</>,
        // salutation: '',
        content: (
            <>
                We appreciate your continued support, and look forward to providing you with an even better writing experience with the new CorrectEnglish<sup>&reg;</sup>.<br/>
                Free Forever users, upgrade today to unlock a range of powerful writing tools!
            </>
        ),
        imgUrl:'https://correctenglish.com/images/modal/upgrade-to-new.png'
    },
];

const SlideComponent:React.FC<{currentIndex: number}> = (props) => {
    const {currentIndex} =  props;
    const currentSlide = slideItemArray[currentIndex];
    return (
        <div className="announcement-slide">
            <h3
                style={{
                    fontFamily: '"Lato", sans-serif',
                    fontSize: "1.15em",
                    fontWeight: 700,
                    lineHeight: "1.25em",
                    textAlign: "center",
                    color: "#303030"
                }}
            >
                {currentSlide?.subTitle}
            </h3>
            <div
                style={{
                    display: "flex",
                    flexWrap: 'wrap',
                    alignItems: "center",
                    justifyContent: "space-between",
                    maxWidth: 1600,
                    marginLeft: "auto",
                    marginRight: "auto"
                }}
            >
                <div className="flex-modal-child" style={{display: "inline", padding: "0 25px"}}>
                    <h3
                        style={{
                            fontFamily: '"Lato", sans-serif',
                            fontSize: "1.15em",
                            fontWeight: 700,
                            lineHeight: "1.5em",
                            color: "#303030"
                        }}
                    >
                        {currentSlide?.salutation}
                    </h3>
                    <p
                        style={{
                            fontFamily: '"Lato", sans-serif',
                            fontSize: "1em",
                            lineHeight: "1.5em",
                            color: "#303030",
                        }}
                    >
                        {currentSlide?.content}
                    </p>
                </div>
                <div className="flex-modal-child" style={{display: "inline", textAlign: "center"}}>
                    <img
                        src={currentSlide.imgUrl}
                        width="450px"
                        style={{textAlign: "center"}}
                    />
                </div>
            </div>
        </div>
    );
}

const DotComponent:React.FC<{scale: number, current: number, handleClick:(current: number)=>void}> = (props) => {
    const {scale, current, handleClick} = props;
    return (
        <>
            {
                Array(scale).fill(scale).map((_, index) => <span
                    key={index}
                    className="dot"
                    style={{
                        cursor: "pointer",
                        height: 15,
                        width: 15,
                        margin: "0 2px",
                        backgroundColor: (index <= current ? '#005B92': "#bbb" ),
                        borderRadius: "50%",
                        display: "inline-block"
                    }}
                    onClick={()=>handleClick(index)}
                />)
            }
        </>
    );
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" timeout={1000} ref={ref} {...props} />;
});

const NewComingAnnouncementPage:React.FC = () => {
    // temporary solution for determination of showing announcement or not. Should hold persist the flag value
    const {checkAnnouncementIsRead, markNewComingAnnouncementRead} = useAnnouncement();
    const [open, setOpen] = useState(!checkAnnouncementIsRead);

    const slideScale = slideItemArray.length;
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const handleNextButton = () => {
        setCurrentSlideIndex(currentSlideIndex + 1);
    }

    const handleClose = () => {
        markNewComingAnnouncementRead();
        setOpen(false);
    }

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                transitionDuration={700}
            >
                <div
                    id="announcement-modal"
                    style={{
                        width: "75%",
                        maxWidth: 1200 /* height: '55%', */,
                        maxHeight: "85%",
                        overflow: "auto",
                        marginLeft: "auto",
                        marginRight: "auto",
                        backgroundColor: "white",
                        position: "fixed",
                        // position: "absolute", 
                        bottom: 0,
                        left: 0,
                        right: 0,
                        padding: 50,
                        borderRadius: "25px 25px 0px 0px",
                        boxShadow: "0px 0px 12px rgba(0, 0, 0, .25)",
                        transform: "translateY(0px)",
                        // ...style
                    }}
                >
                    <div
                        id="announcement-close"
                        onClick={handleClose}
                        style={{
                            display: "inline",
                            float: "right",
                            position: "relative",
                            cursor: "pointer"
                        }}
                    >
                        <svg width={25} height={25} viewBox="0 0 25 25">
                            <g
                                id="Group_6498"
                                data-name="Group 6498"
                                transform="translate(-1147.146 -143.146)"
                            >
                                <line
                                    id="Line_260"
                                    data-name="Line 260"
                                    x1={25}
                                    y2={25}
                                    transform="translate(1147.5 143.5)"
                                    fill="none"
                                    stroke="#303030"
                                    strokeWidth={1}
                                />
                                <line
                                    id="Line_261"
                                    data-name="Line 261"
                                    x2={25}
                                    y2={25}
                                    transform="translate(1147.5 143.5)"
                                    fill="none"
                                    stroke="#303030"
                                    strokeWidth={1}
                                />
                            </g>
                        </svg>
                    </div>
                    <h1
                        style={{
                            fontFamily: '"Raleway", sans-serif',
                            textDecorationColor: "#0B845C",
                            fontSize: "2em",
                            fontWeight: 900,
                            textAlign: "center",
                            color: "#000"
                        }}
                    >
                        {/*<u*/}
                        {/*    style={{*/}
                        {/*        textDecoration: "none !Important",*/}
                        {/*        borderBottom: "3px solid #0B845C"*/}
                        {/*    }}*/}
                        {/*>*/}
                            Introducing the New CorrectEnglish<sup>®</sup>
                        {/*</u>*/}
                    </h1>
                    <SlideComponent currentIndex={currentSlideIndex} />
                    <div className="announcement-modal__footer">

                 

                                <div style={{textAlign: "center", marginTop: 15, marginBottom: 15}}>
                                    {
                                        <DotComponent
                                            scale={slideScale}
                                            current={currentSlideIndex}
                                            handleClick={(index)=>setCurrentSlideIndex(index)}
                                        />
                                    }
                                </div>

                                {
                                    currentSlideIndex === 0
                                    && <button id="whats-new-scoobydoo"
                                        style={{
                                            display:'block',
                                            textDecoration:'none',
                                            cursor:'pointer',
                                            backgroundColor:'#005B92',
                                            border: 'none',
                                            outline: 'none',
                                            borderRadius: '99px',
                                            padding: '5px 25px',
                                            marginLeft: 'auto', marginRight: 'auto',
                                            fontFamily: '"Raleway", sans-serif',
                                            fontSize: '1.15em',
                                            fontWeight: '600',
                                            textAlign: 'center',
                                            color: '#FFF'}}
                                        onClick={handleNextButton}
                                        >
                                            What's New?
                                        </button>
                                }
                                {
                                    (currentSlideIndex > 0 && currentSlideIndex < slideScale-1)
                                    && <button id="slide-progressor"
                                        style={{
                                            display:'block',
                                            textDecoration:'none',
                                            cursor:'pointer',
                                            backgroundColor:'#005B92',
                                            border: 'none',
                                            outline: 'none',
                                            borderRadius: '99px',
                                            padding: '5px 25px',
                                            marginLeft: 'auto', marginRight: 'auto',
                                            fontFamily: '"Raleway", sans-serif',
                                            fontSize: '1.15em',
                                            fontWeight: '600',
                                            textAlign: 'center',
                                            color: '#FFF'}}
                                        onClick={handleNextButton}
                                        >
                                            Next {">>"}
                                        </button>
                                }
                                {
                                    (currentSlideIndex === slideScale - 1)
                                    && <button id="upgrade-progressor"
                                        style={{
                                            display:'block',
                                            textDecoration:'none',
                                            cursor:'pointer',
                                            backgroundColor:'#0B845C',
                                            border: 'none',
                                            outline: 'none',
                                            borderRadius: '99px',
                                            padding: '5px 25px',
                                            marginLeft: 'auto', marginRight: 'auto',
                                            fontFamily: '"Raleway", sans-serif',
                                            fontSize: '1.15em',
                                            fontWeight: '600',
                                            textAlign: 'center',
                                            color: '#FFF'}}
                                            onClick={() => {
                                                handleClose();
                                                window.location.href = 'https://www.correctenglish.com/#plans'
                                            }}
                                            role="button"
                                        >
                                            Upgrade CorrectEnglish
                                        </button>
                                }


                    </div>

                </div>
            </Dialog>
        </>
    );
}

export default NewComingAnnouncementPage;