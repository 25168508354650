import React from "react";
import {useNavigate} from "react-router-dom";
import OnboardPricingPlan from "../OnboardPricingPlan";
import OnboardWebFinish from "./OnboardWebFinish";

type Props = {
    startStep: number
}
const WebFlowIndex: React.FC<Props> = (props) => {
    const {startStep} = props;
    const [activeStep, setActiveStep] = React.useState(startStep);
    const navigate = useNavigate();
    const genericHandleInterrupt = () => navigate("/");
    const genericHandleContinue = () => setActiveStep(s => s + 1);
    const currentUrl = window.location.origin + '/welcome';

    return (
        <>
            {
                activeStep === 0 &&
                <OnboardPricingPlan
                    continueButtonHandler={genericHandleContinue}
                    interruptHandler={genericHandleInterrupt}
                    purchaseSuccessUrl={currentUrl + '?s=1'}
                    purchaseFailureUrl={currentUrl + '?s=0'}
                />
            }

            {
                activeStep === 1 &&
                <OnboardWebFinish
                    continueButtonHandler={genericHandleContinue}
                    interruptHandler={genericHandleInterrupt}
                />
            }
        </>
    );
}

export default WebFlowIndex;