import React, { useState, useEffect } from "react";
import {loadStripe} from "@stripe/stripe-js";
import {useKeycloak} from "@react-keycloak/web";
import {Button} from "@mui/material";
import {useAppDispatch, useAxios} from "../../../utils/hooks";
import {updateStripeSessionPaymentInfo} from "../../../redux/slices/stripeSessionPaymentInfoSlice"
import {useCookies} from "react-cookie";
import ExtensionProductDisplay from "./ExtensionProductDisplay";
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe("pk_test_chUoTb4BRbp5JflaS4Frzvuq");

const CE_API_CREATE_PAYMENT_INTENT_ENDPOINT = process.env.REACT_APP_CE_API_DOMAIN
                                                + "/purchase/stripe/create-payment-intent";
const CE_API_CREATE_PAYMENT_SESSION_ENDPOINT = process.env.REACT_APP_CE_API_DOMAIN
    + "/purchase/stripe/create-checkout-session";

const Message = ({ message }: {message:string}) => (
    <section>
        <p>{message}</p>
    </section>
);
// const StripePayment: React.FC = () => {
//     const { keycloak } = useKeycloak();
//     const [clientSecret, setClientSecret] = useState("");
//
//     useEffect(() => {
//         // Create PaymentIntent as soon as the page loads
//         fetch(CE_API_CREATE_PAYMENT_INTENT_ENDPOINT, {
//             method: "POST",
//             headers: { "Content-Type": "application/json", accept: 'application/json' ,Authorization: `Bearer ${keycloak?.token}` },
//             body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
//         })
//             .then((res) => res.json())
//             .then((data) => setClientSecret(data.clientSecret));
//     }, []);
//
//     const appearance = {
//         theme: "stripe",
//     };
//     const options = {
//         clientSecret,
//         appearance,
//     };
//
//     return (
//         <div className="StripePayment">
//             {clientSecret && (
//                 // @ts-ignore
//                 <Elements options={options} stripe={stripePromise}>
//                     <StripeCheckoutForm />
//                 </Elements>
//             )}
//         </div>
//     );
// }

const StripePayment: React.FC = () => {
    const [message, setMessage] = useState("");

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
            setMessage("Order placed! You will receive an email confirmation.");
        }

        if (query.get("canceled")) {
            setMessage(
                "Order canceled -- continue to shop around and checkout when you're ready."
            );
        }
    }, []);

    return message ? (
        <Message message={message} />
    ) : (
        <ExtensionProductDisplay />
    );
}

export default StripePayment;