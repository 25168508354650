import {useEffect, useRef} from 'react';
import type {AxiosInstance} from 'axios';
import axios from 'axios';

import {AppDispatch, RootState} from "../redux/store";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import Keycloak from "keycloak-js";
import { browserName } from 'react-device-detect';
import {CE_API_BASE_URL, ExtensionStoreLinks} from "./constants";
import {updateCurrentPlan, updateSubscriptions} from "../redux/slices/consumerSubscriptionSlice";
import {parsePlanFromKeycloak} from "./isExtensionPremium";
import {useKeycloak} from "@react-keycloak/web";
import {useCookies} from "react-cookie";

export const useAxios = (baseURL: string, keycloak?: Keycloak.KeycloakInstance) => {
    const axiosInstance = useRef<AxiosInstance>();
    const kcToken = keycloak?.token ?? '';

    useEffect(() => {
        axiosInstance.current = axios.create({
            baseURL,
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
                Authorization: keycloak?.authenticated ? `Bearer ${kcToken}` : '',
            },
            withCredentials: true,
        });

        return () => {
            axiosInstance.current = undefined;
        };
    }, [baseURL, kcToken, keycloak?.authenticated]);

    return axiosInstance;
};

export const useNonAuthAxios = (baseURL: string) => {
    const axiosInstance = useRef<AxiosInstance>();

    useEffect(() => {
        axiosInstance.current = axios.create({
            baseURL,
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
            withCredentials: true,
        });

        return () => {
            axiosInstance.current = undefined;
        };
    }, [baseURL]);

    return axiosInstance;
};

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useBrowserExtensionStore = () => {
    const currentBrowser = browserName;
    let extensionStoreLink = ExtensionStoreLinks[currentBrowser];
    if (!extensionStoreLink) {
        return ExtensionStoreLinks['Chrome'];
    }
    return extensionStoreLink;
}

export const useCESubscription = () => {
    const { initialized, keycloak } = useKeycloak();
    const axios = useAxios(CE_API_BASE_URL, keycloak);
    const dispatch = useAppDispatch();

    const updateUserSubscriptions = (success?: () => void, failure?: () => void) => {
        if (initialized && !!keycloak) {
            dispatch(updateCurrentPlan(parsePlanFromKeycloak(keycloak)));

            axios?.current?.post(
                "/subscription"
            ).then(r => {
                dispatch(updateSubscriptions(r?.data));
                keycloak.updateToken(300)
                    .then(refreshed => {
                        if (refreshed) {
                            dispatch(
                                updateCurrentPlan(
                                    parsePlanFromKeycloak(keycloak)));
                        }
                    });

                !!success && success();
            }).catch(e => !!failure && failure());
        }
    }

    return {
        updateUserSubscriptions
    };
}

export const useCEUserService = () => {
    const { initialized, keycloak } = useKeycloak();
    const axios = useAxios(CE_API_BASE_URL, keycloak);
    const dispatch = useAppDispatch();

    const deleteUser = (success?: () => void, failure?: (e?) => void) => {
        if (initialized && !!keycloak) {
            dispatch(updateCurrentPlan(parsePlanFromKeycloak(keycloak)));

            axios?.current?.delete(
                "/users/kcusers/user"
            ).then(r => {
                !!success && success();
            }).catch(e => !!failure && failure(e));
        }
    }

    return {
        deleteUser
    };
}

export const useAnnouncement = () => {
    const [cookies, setCookies, removeCookies] = useCookies(['_anncmntRd']);
    const newAnnouncementIsReadFlag = cookies['_anncmntRd'];

    const checkAnnouncementIsRead = (typeof newAnnouncementIsReadFlag !== 'undefined')
    && ((typeof newAnnouncementIsReadFlag === 'string' && newAnnouncementIsReadFlag === 'true') || (typeof newAnnouncementIsReadFlag === 'boolean' && newAnnouncementIsReadFlag));

    const current = new Date();
    const nextYear = new Date();
    nextYear.setFullYear(current.getFullYear() + 1);

    const markNewComingAnnouncementRead = () => {
        setCookies('_anncmntRd', true, {path: '/', expires: nextYear});
    }

    const markNewComingAnnouncementUnRead = () => {
        setCookies('_anncmntRd', false, {path: '/'});
        removeCookies('_anncmntRd', {path: '/'});
    }
    return {
        checkAnnouncementIsRead,
        markNewComingAnnouncementRead,
        markNewComingAnnouncementUnRead
    };
}
