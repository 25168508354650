import React, {useState} from "react";
import {
    Button, CircularProgress, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid, Typography
} from "@mui/material";
import {useAppSelector, useCEUserService} from "../../utils/hooks";
import {isExtensionPremiumPlan} from "../../utils/utils";
import {useKeycloak} from "@react-keycloak/web";
import {USER_ACCOUNT_DELETION_REDIRECT_URL} from "../../utils/constants";

export const AccountDeletionBlock: React.FC = () => {
    const { keycloak } = useKeycloak();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [childModalOpen, setChildModalOpen] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [loadingCircularProgress, setLoadingCircularProgress] = useState(false);

    const handleChildModalOpen = (msgBody:string) => {
        setErrorBody(msgBody);
        setChildModalOpen(true);
    }
    const handleChildModalClose = () => setChildModalOpen(false);

    const { deleteUser } = useCEUserService();
    const subscriptions = useAppSelector(state => state.consumerSubscriptions.subs);

    const processUserAccountDeletion = () => {
        setLoadingCircularProgress(true);
        deleteUser(
            () => {
                setLoadingCircularProgress(false);
                handleChildModalOpen("Your account has been deleted!");
                setTimeout(()=> {
                    keycloak?.logout({redirectUri: USER_ACCOUNT_DELETION_REDIRECT_URL})
                }, 3000);
            },
            (err?) => {
                (typeof err?.data === 'undefined')
                    ? handleChildModalOpen("Error! Failed to delete account!")
                    : handleChildModalOpen(err?.data)
                setLoadingCircularProgress(false);
            }
        );
    }

    const handleUserDeletionFlow = () => {
        (!Array.isArray(subscriptions)) && handleChildModalOpen("Error, cannot get your subscription information. Try it later.");
        const notAllowedToDeleteUser = Array.isArray(subscriptions) && subscriptions.some(sub => {
            if(isExtensionPremiumPlan(sub) && sub?.isAutoRenew) {
                handleChildModalOpen("You must cancel your premium subscription before deleting your account.");
                return true;
            }

        })

        !notAllowedToDeleteUser && handleOpen();
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        style={{ fontFamily: 'Raleway'}}
                        className="setting-subtitle" >
                        Delete Account
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <span>Remove your account and all associated data. This action cannot be undone.</span>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        id="accountDeletionButton"
                        variant="outlined"
                        type="submit"
                        style={{textTransform:'none'}}
                        color="warning"
                        onClick={handleUserDeletionFlow}
                    >
                        Delete your account
                    </Button>
                </Grid>
            </Grid>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Do you really want to delete your account?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {"You will lose access immediately and all of your data will be permanently deleted, regardless of the days remaining in your subscription."}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={processUserAccountDeletion}
                        disabled={loadingCircularProgress}
                        endIcon={loadingCircularProgress && <CircularProgress size={24} color='success'/>}
                    >
                        Yes
                    </Button>
                    <Button onClick={handleClose} autoFocus>
                        No
                    </Button>


                </DialogActions>
            </Dialog>
            <ChildModal
                title={"Cannot delete your account"}
                body={errorBody}
                open={childModalOpen}
                handleClose={handleChildModalClose}
            />
        </>
    );
};

const ChildModal:React.FC<{title: string, body:string, open: boolean, handleClose:()=>void}> = (props) => {
    const {title, body, open, handleClose} = props;

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {body}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}