import React from "react";
import {useKeycloak} from "@react-keycloak/web";
import {Layout} from "../Layout";
import {
    Button,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    Divider, Link as MatLink,
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography
} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Icon } from '@mui/material';
import {
    CLASSIC_CORRECTENGLISH_URL,
    EXTENSION_CHROME_WEB_STORE_URL,
    WRITEAHEAD_PURCHASE_URL
} from "../../utils/constants";
import {checkIfExtensionInstalled} from "../../utils/utils";
import {browserName, isDesktop, isMobile, isSafari} from "react-device-detect";
import {useBrowserExtensionStore} from "../../utils/hooks";
import useCeMetrics from "../../metrics/useCeMetrics";
import {ParsedToken} from "../../utils/types/keycloakExtraType";
import iosBadge from './img/badge.ios-store.svg';
import androidBadge from './img/badge.play-store.svg';
import NewComingAnnouncementPage from "../onboarding/NewComingAnnouncementPage";


/**
 * Displays appropriate info or button for extension row.
 * For safari, we show coming soon; for others, we detect if users install extension
 * TODO change it back when we support safari
 */
const ExtensionOptionElement = () => {
    const extensionWebStoreLink = useBrowserExtensionStore();
    if (isSafari) {
        return (
            <ListItem
                sx={{fontFamily: 'Raleway', flexWrap: "wrap"}}
                // secondaryAction={p.button}
            >
                <ListItemText
                    primary={
                        <Typography
                            style={{ fontFamily: 'Raleway', fontWeight: 'bold', color: '#161616', display: 'flex', justifyContent: 'flex-end'}}
                            variant="h6"
                            component="div"
                        >
                            Coming Soon
                        </Typography>
                    }
                    secondary={
                        <Typography
                            style={{ fontFamily: 'Raleway', color: '#5a5a5a'}}
                            variant="subtitle2"
                            component="div"
                        >
                            Check out our browser extension for Chrome, Firefox, and Edge!
                        </Typography>}
                />
            </ListItem>
        );
    } else {
        return (
            checkIfExtensionInstalled() ?
                <span><CheckCircleIcon style={{fill: '#5eba27',  color: '#5eba27'}} /> Installed</span>
                :<Button
                    href={extensionWebStoreLink}
                    variant="contained"
                    type="button"
                    style={{textTransform:'none', padding: '6px 23px 6.5px', borderRadius: '100px', backgroundColor: '#196ea1', width: '150px'}}
                >
                    Download
                </Button>
        );
    }
};

const ProductsPage:React.FC = () => {
    const { keycloak } = useKeycloak();
    const currentBrowser = browserName;
    const extensionWebStoreLink = useBrowserExtensionStore();
    const parsedToken: ParsedToken | undefined = keycloak?.tokenParsed;
    const {sendEventToApiWithAuth, readTrackSessionToken} = useCeMetrics(keycloak);
    const trackSessionToken = readTrackSessionToken();

    // ---------------  set up for disabling the downloading keyboard button and replacing with a modal ----------
    const [openModal, setModalOpen] = React.useState(false);
    const handleModalClickOpen = () => {
        setModalOpen(true);
    };
    const handleModalClose = () => {
        setModalOpen(false);
    };
    //---------------------------- end ----------------------------------------

    const pageDetails = {
        title: 'Ways to use CorrectEnglish'
    }

    const productsListForDesktop = [
        {
            title: 'CorrectEnglish Editor',
            subTitle: 'Online text editor and writing skill checker',
            button: isDesktop ?
                <Button
                    href={CLASSIC_CORRECTENGLISH_URL}
                    variant="contained"
                    fullWidth
                    type="button"
                    style={{textTransform:'none', padding: '6px 23px 6.5px', borderRadius: '100px', backgroundColor: '#196ea1', width: '150px'}}
                    onClick={() => {
                        const event = [{
                            type: "LAUNCH_EDITOR",
                            data: {
                                username: keycloak?.authenticated ? parsedToken?.email : "non_logged_in",
                                browser: browserName,
                            }
                        }];
                        (typeof trackSessionToken === 'string') && sendEventToApiWithAuth(trackSessionToken, event);
                    }}
                >
                      Open
                </Button> : <></>
        },
        {
            title: 'CorrectEnglish Browser Extension for ' + currentBrowser,
            subTitle: 'Checks your writing anywhere on the web',
            button: <ExtensionOptionElement />
        },
        {
            title: 'CorrectEnglish Mobile Keyboard',
            subTitle: 'Writing keyboard for phones and tablets',
            button:
            <>
            <a href="https://play.google.com/store/apps/details?id=com.vantage.correctenglishbeta&pli=1" target="_self"><img style={{height:'37px', width: 'auto', marginRight: '1em'}} src={androidBadge} alt={"Get CorrectEnglish on the Play Store"} /></a>
            <img style={{height:'37px', width: 'auto'}} src={iosBadge} alt={"Get CorrectEnglish of the iOS App Store"}/>
            </>
        },
        {
            title: 'WriteAhead',
            subTitle: 'Connects you with a personal writing tutor',
            button:
                <Button
                    href={WRITEAHEAD_PURCHASE_URL}
                    variant="contained"
                    type="button"
                    style={{textTransform:'none', padding: '6px 23px 6.5px', borderRadius: '100px', backgroundColor: '#5eba27', width: '150px'}}
                >
                    Find a Tutor
                </Button>
        },
    ];

    const productsListForMobileDevice = [
        {
            title: 'CorrectEnglish Mobile Keyboard',
            subTitle: 'Writing keyboard for phones and tablets',
            button:
                <Button
                    onClick={()=>handleModalClickOpen()}
                    variant="contained"
                    type="button"
                    style={{textTransform:'none', padding: '6px 23px 6.5px', borderRadius: '100px', backgroundColor: '#196ea1', width: '150px'}}
                >
                    Download
                </Button>
        },
        {
            title: 'CorrectEnglish Editor',
            subTitle: 'Online text editor and writing skill checker',
            button: isDesktop ?
                <Button
                    href={CLASSIC_CORRECTENGLISH_URL}
                    variant="contained"
                    fullWidth
                    type="button"
                    style={{textTransform:'none', padding: '6px 23px 6.5px', borderRadius: '100px', backgroundColor: '#196ea1', width: '150px'}}
                >
                    Open
                </Button> : <></>
        },
        {
            title: 'CorrectEnglish Browser Extension for ' + currentBrowser,
            subTitle: 'Checks your writing anywhere on the web',
            button: isDesktop ?
                <Button
                    href={extensionWebStoreLink}
                    disabled={checkIfExtensionInstalled()}
                    variant="contained"
                    type="button"
                    style={{textTransform:'none', padding: '6px 23px 6.5px', borderRadius: '100px', backgroundColor: '#196ea1', width: '150px'}}
                >
                    Download
                </Button> : <></>
        },
        {
            title: 'WriteAhead',
            subTitle: 'Connects you with a personal writing tutor',
            button:
                <Button
                    href={WRITEAHEAD_PURCHASE_URL}
                    variant="contained"
                    type="button"
                    style={{textTransform:'none', padding: '6px 23px 6.5px', borderRadius: '100px', backgroundColor: '#5eba27', width: '150px'}}
                >
                    Find a Tutor
                </Button>
        },
    ];

    const products = isDesktop ? productsListForDesktop : productsListForMobileDevice;
    return (
        keycloak?.authenticated ?
            <>
                <NewComingAnnouncementPage />
                <Layout title={"My Apps"}>

                    <Typography
                        style={{ fontFamily: 'Raleway', fontWeight: 'bold', color: '#161616', marginBottom: "1em"}}
                        variant="h3"
                        component="h1"
                    >
                        {pageDetails.title}
                    </Typography>

                    <Paper className="hub-actions-container" elevation={0} sx={{ width: '100%', bgcolor: 'background.paper', fontFamily: 'Raleway'}}>
                        <List>
                            {
                                products.map((p, index) =>
                                    <React.Fragment key={index}>
                                        {index !== 0 ? <Divider/> : <></>}
                                        <ListItem
                                            sx={{fontFamily: 'Raleway', flexWrap: "wrap"}}
                                            secondaryAction={p.button}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        style={{ fontFamily: 'Raleway', fontWeight: 'bold', color: '#161616'}}
                                                        variant="h6"
                                                        component="div"
                                                    >
                                                        {p.title}
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Typography
                                                        style={{ fontFamily: 'Raleway', color: '#5a5a5a'}}
                                                        variant="subtitle2"
                                                        component="div"
                                                    >
                                                        {p.subTitle}
                                                    </Typography>}
                                            />
                                        </ListItem>
                                    </React.Fragment>
                                )
                            }
                        </List>
                        {/* TODO Only for disabling downloading keyboard app. Will remove it in the future.*/}
                        <Dialog
                            open={openModal}
                            onClose={handleModalClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Keyboard app is coming soon"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Please contact us via
                                    <MatLink href="mailto:support@correctenglish.com" color="#005B92"> support@correctenglish.com </MatLink>
                                    for more information.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleModalClose} autoFocus>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {/*---------------------------- end of modal ------------------------------*/}
                    </Paper>
                </Layout>
            </> : <></>
    );
}
export default ProductsPage;