import React from "react";
import * as yup from "yup";
import {useFormik} from "formik";
import {
    Button, Divider,
    TextField,
    Typography,
    Grid
} from "@mui/material";
import {useKeycloak} from "@react-keycloak/web";
import {useAxios} from "../../utils/hooks";
import {CE_API_BASE_URL} from "../../utils/constants";
import {ParsedToken} from "../../utils/types/keycloakExtraType";

const languages = {
    "English": "en",
    "Chinese": "cn",
    "French": "fr"
};

const validationSchema = yup.object({
    firstName: yup.string()
        .required("First name cannot be empty"),
    lastName: yup.string()
        .required("Last name cannot be empty")
});

const UserProfileBlock: React.FC = () => {

    const { keycloak } = useKeycloak();
    const parsedToken: ParsedToken | undefined = keycloak?.tokenParsed
    const axios = useAxios(CE_API_BASE_URL, keycloak);

    const formik = useFormik({
        initialValues: {
            email: parsedToken?.email ?? '',
            username: parsedToken?.preferred_username,
            firstName: parsedToken?.given_name,
            lastName: parsedToken?.family_name,
            language: parsedToken?.language ?? 'fr',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            axios.current?.put("/users/kcusers/" + parsedToken?.sub + "/profile",
                {
                    firstName: values.firstName,
                    lastName: values.lastName
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        accept: 'application/json',
                    },
                }).then(res => {
                alert("Updated user profile successfully!")
                console.log(res.data)

            }).catch(e => alert("failed to communicate with server"));
        },
    });

    return (
        <form onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                <Grid container spacing={2}>

                    <Grid item xs={12}>
                            <Typography variant="h6" noWrap component="div" style={{ fontFamily: 'Raleway'}} className="setting-subtitle" >
                                User Profile
                            </Typography>
                            <Divider/>
                            <br/>
                    </Grid>
                    
                    
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <label className="settings-label">Email:</label>
                    </Grid>
                    
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <label style={{wordBreak:'break-all'}}>{parsedToken?.email}</label>
                    </Grid>


                    <Grid item xs={12} sm={12} md={6} lg={6}>
                         <label className="settings-label">Username:</label>
                    </Grid>
                    
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <label style={{wordBreak:'break-all'}}>{parsedToken?.preferred_username}</label>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <label htmlFor="firstName" className="settings-label">First Name:</label>
                    </Grid>
                    
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                                    id="firstName"
                                    name="firstName"
                                    fullWidth
                                    size="small"
                                    value={formik.values.firstName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                    helperText={formik.touched.firstName && formik.errors.firstName}
                                    inputProps={{
                                        style: {
                                            fontFamily: 'Raleway',
                                            color: '#707070'
                                        }
                                    }}
                                />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <label htmlFor="lastName" className="settings-label">Last Name:</label>
                    </Grid>
                    
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                                    id="lastName"
                                    name="lastName"
                                    fullWidth
                                    size="small"
                                    value={formik.values.lastName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                    helperText={formik.touched.lastName && formik.errors.lastName}
                                    inputProps={{
                                        style: {
                                            fontFamily: 'Raleway',
                                            color: '#707070'
                                        }
                                    }}
                                />
                    </Grid>
                    <Grid item xs={12}>
                                <Button id={'profileChangeButton'} className="settings-page-button"
                                       // fullWidth
                                       variant="contained" type="submit" style={{textTransform:'none'}}>
                                   Update
                               </Button>
                    </Grid>
                </Grid>
        </form>
    );
};

export default UserProfileBlock;

// Language Selection code for future usage
// <tr>
//     <td>
//         <label htmlFor="language">Language:</label>
//         {/*<InputLabel id="language">Language</InputLabel>*/}
//     </td>
//     <td>
//         {/*<InputLabel id="language">Language</InputLabel>*/}
//         <Select
//             id="language"
//             variant="outlined"
//             value={formik.values.language}
//             onChange={formik.handleChange}
//             error={formik.touched.language && Boolean(formik.errors.language)}
//         >
//             <MenuItem value={'en'}>English</MenuItem>
//             <MenuItem value={'fr'}>French</MenuItem>
//             <MenuItem value={'cn'}>Chinese</MenuItem>
//         </Select>
//     </td>
// </tr>