import {configureStore} from "@reduxjs/toolkit";
import consumerSubscriptionsReducer from "../redux/slices/consumerSubscriptionSlice"
import documentReducer from "./slices/documentSlice";
export const store = configureStore({
    reducer: {
        // stripeSessionPaymentInfo: stripePaymentSliceReducer,
        consumerSubscriptions: consumerSubscriptionsReducer,
        document: documentReducer
    }
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch