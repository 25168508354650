import React from "react";
import {ListItemButton, ListItemIcon, ListItemText, Typography} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export type SidebarItemType = {
    name?: string,
    subtitle?: string,
    url?: string,
    iconPath?: string,
    iconComponent?: React.ReactNode,
    performFunction?: Function,
    sameTabForOutsideUrl?: boolean // only works for urls with full http url
}


export const SidebarItem: React.FC<SidebarItemType> = (props: SidebarItemType) => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = () => {
        if(props.performFunction && typeof props.performFunction === "function") {
            props.performFunction();
        }

        if (props?.url?.toLocaleLowerCase().startsWith('http')) {
            window.open(props.url, (props.sameTabForOutsideUrl === true ? '_self' : '_blank'));
            return
        }

        navigate(props.url ? props.url : '/');
    };

    return (
        <ListItemButton
            className='sidebarItem font-raleway'
            sx={{py: 0}}
            dense
            alignItems='center'
            onClick={handleClick}
            selected={location.pathname === props.url}
        >
            {
                props.iconPath ? <ListItemIcon sx={{minWidth: 30}}><img src={props.iconPath} alt={props.name}/></ListItemIcon> :
                    (props.iconComponent && <ListItemIcon sx={{minWidth: 30}}>{props.iconComponent}</ListItemIcon>)
            }
            <ListItemText
                disableTypography
                primary={props.name}
                style={{fontFamily: 'Raleway', fontSize: '14px'}}
                secondary={!props.subtitle ? null :
                    <Typography
                        style={{ fontFamily: 'Raleway', color: '#5a5a5a', fontSize: '12px'}}
                        variant="subtitle2"
                        noWrap
                        component="div"
                    >
                        {props.subtitle}
                    </Typography>
                }
            />
            {
                !!(props?.name) && <KeyboardArrowRightIcon />
            }
        </ListItemButton>
    )
}