export const CLASSIC_CORRECTENGLISH_URL = !!(process.env.REACT_APP_CLASSIC_CORRECTENGLISH_URL)
                                        ? process.env.REACT_APP_CLASSIC_CORRECTENGLISH_URL
                                        : 'https://writing.correctenglish-staging.com';

export const CE_API_BASE_URL = !!(process.env.REACT_APP_CE_API_DOMAIN)
                            ? process.env.REACT_APP_CE_API_DOMAIN
                            : "https://api.correctenglish.com";

export const EXTENSION_CHROME_WEB_STORE_URL = !!(process.env.REACT_APP_EXTENSION_CHROME_WEB_STORE_URL)
                                        ? process.env.REACT_APP_EXTENSION_CHROME_WEB_STORE_URL
                                        : undefined;
export const EXTENSION_FIREFOX_WEB_STORE_URL = !!(process.env.REACT_APP_EXTENSION_FIREFOX_WEB_STORE_URL)
                                        ? process.env.REACT_APP_EXTENSION_FIREFOX_WEB_STORE_URL
                                        : undefined;
export const EXTENSION_SAFARI_WEB_STORE_URL = !!(process.env.REACT_APP_EXTENSION_SAFARI_WEB_STORE_URL)
                                        ? process.env.REACT_APP_EXTENSION_SAFARI_WEB_STORE_URL
                                        : undefined;
export const EXTENSION_EDGE_WEB_STORE_URL = !!(process.env.REACT_APP_EXTENSION_EDGE_WEB_STORE_URL)
                                        ? process.env.REACT_APP_EXTENSION_EDGE_WEB_STORE_URL
                                        : undefined;
export const EXTENSION_UNINSTALL_REDIRECT_URL = !!(process.env.REACT_APP_EXTENSION_UNINSTALL_REDIRECT_URL)
                                        ? process.env.REACT_APP_EXTENSION_UNINSTALL_REDIRECT_URL
                                        : "https://correctenglish.com/uninstall";

export const USER_ACCOUNT_DELETION_REDIRECT_URL = !!(process.env.REACT_APP_USER_ACCOUNT_DELETION_REDIRECT_URL)
                                        ? process.env.REACT_APP_USER_ACCOUNT_DELETION_REDIRECT_URL
                                        : "https://marketing.correctenglish.com/delete-account";

export const CE_API_USERS_ROUTE = 'users';
export const CE_API_KEYCLOAK_USER_ROUTE = 'kcusers';
export const CE_BLOG_URL = 'https://www.correctenglish.com/blog';
export const CE_FEEDBACK_URL = 'https://www.formstack.com/forms/?1621873-wREgF6tdd7';
export const CE_USER_GUIDE = 'https://resource.vantage.com/display/CEWEB/CorrectEnglish+-+User+Guide';
export const WRITEAHEAD_PURCHASE_URL = 'https://www.writeahead.com/';
export const EXTENSION_PREMIUM_ROLES = ['ROLE_EXTENSION_PREMIUM_USER'];


export const SUBSCRIPTION_DURATION_KEY_NAME = 'subscriptionDuration';
export const STRIPE_SESSION_COOKIE_NAME = 'stripe-session';

export const ROISOURCE_KEY_NAME = 'rioSource';
export const ORDER_SOURCE_KEY_NAME = 'source';
export const PERSONA_KEY_NAME = 'persona';
export const INTENTION_KEY_NAME = 'intention';
export const CAMPAIGN_KEY_NAME = 'campaign';
export const REDIRECT_URL_KEY_NAME = 'redirect';
export const MARKETING_ATTRIBUTES_COOKIES_KEY_NAME = '_mktparam';

export const EX_PREMIUM_ECOMMERCE_SUBTYPE_NAME = 'CEX PREMIUM';
export const EX_FREE_FOREVER_ECOMMERCE_SUBTYPE_NAME = 'CEX FREE';
export const BRAND_NAME = 'CorrectEnglish';

export const PRODUCT_PATH_PARAMETER_KEY_NAME = 'p';
export const PRODUCT_VERSION_KEY_NAME = 'v';

export const ExtensionStoreLinks = {
    "Chrome": EXTENSION_CHROME_WEB_STORE_URL,
    "Firefox": EXTENSION_FIREFOX_WEB_STORE_URL,
    "Safari": EXTENSION_SAFARI_WEB_STORE_URL,
    "Edge": EXTENSION_EDGE_WEB_STORE_URL
};

export const GOOGLE_TAG_URL = 'https://www.googletagmanager.com/gtag/js?id=G-188NG4FG1X';
