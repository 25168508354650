import React, { useEffect } from "react";
import outlookIcon from "../../../assets/outlook.svg";
import gmailIcon from "../../../assets/gmail.svg";
import googleDocIcon from "../../../assets/google-docs.svg";
import googleClassroomIcon from "../../../assets/google-classroom.svg";
import linkedinIcon from "../../../assets/linkedin.svg";
import twitterIcon from "../../../assets/twitter.svg";
import slackIcon from "../../../assets/slack.svg";
import facebookIcon from "../../../assets/facebook.svg";
import monsterIcon from "../../../assets/monster.svg";
import indeedIcon from "../../../assets/indeed.svg";
import {GenericOnboardComponentProps} from "../WelcomePage";
import {CLASSIC_CORRECTENGLISH_URL} from "../../../utils/constants";

const OnboardExtensionFinish: React.FC<GenericOnboardComponentProps> = (props) => {
    const { continueButtonHandler, interruptHandler } = props;
    
    useEffect(() => {
        document.body.className = "ce-background";
        return () => {
            document.body.className = "";
        }
    }, []);
    
    return (
        <>
            <div className="header-box logo"></div>
            <div id="features" className="card text-center">

                <h1>You're all set!</h1>
                <h5>We're excited to support your writing excellence!<br />
                    Where are we writing first?</h5>

                <div className="flex-row justified">

                    <a href="https://outlook.office.com/">
                        <div className="writing-type-selector">
                            <img src={outlookIcon}  alt='outlook'/>
                        </div>
                    </a>

                    <a href="https://mail.google.com/">
                        <div className="writing-type-selector">
                            <img src={gmailIcon}  alt='gmail'/>
                        </div>
                    </a>

                    <a href="https://docs.google.com/">
                        <div className="writing-type-selector">
                            <img src={googleDocIcon} />
                        </div>
                    </a>

                    <a href="https://classroom.google.com/">
                        <div className="writing-type-selector">
                            <img src={googleClassroomIcon} />
                        </div>
                    </a>

                    <a href="https://www.linkedin.com">
                        <div className="writing-type-selector">
                            <img src={linkedinIcon} />
                        </div>
                    </a>
                </div>

                <div className="flex-row justified mt-2 offset">

                    <a href="https://twitter.com">
                        <div className="writing-type-selector">
                            <img src={twitterIcon} />
                        </div>
                    </a>

                    <a href="https://slack.com/">
                        <div className="writing-type-selector">
                            <img src={slackIcon} />
                        </div>
                    </a>

                    <a href="https://www.facebook.com">
                        <div className="writing-type-selector">
                            <img src={facebookIcon} />
                        </div>
                    </a>

                    <a href="https://www.monster.com">
                        <div className="writing-type-selector">
                            <img src={monsterIcon} />
                        </div>
                    </a>

                    <a href="https://www.indeed.com">
                        <div className="writing-type-selector">
                            <img src={indeedIcon} />
                        </div>
                    </a>
                </div>

                <p><strong>Did you know we also have a writing space on the web?</strong></p>

                <button
                    className="long-btn extra fullwidth mt-2"
                    onClick={() => window.location.href=CLASSIC_CORRECTENGLISH_URL}
                >
                    Take me to the CorrectEnglish editor
                </button>
            </div>
        </>
    );
}

export default OnboardExtensionFinish