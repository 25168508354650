import * as React from 'react'
import { useKeycloak } from '@react-keycloak/web'
import {Layout} from "./Layout";
import CeExtButton from './ceExtensionDetector';
import {useAppDispatch, useAxios} from "../utils/hooks";
import {ParsedToken} from "../utils/types/keycloakExtraType";
import {Box, Button, Card, CardActions, CircularProgress} from "@mui/material";
import {useState} from "react";
import {updateCurrentPlan} from '../redux/slices/consumerSubscriptionSlice';
import {parsePlanFromKeycloak} from "../utils/isExtensionPremium";

const HomePage: React.FC = () => {
    const { keycloak } = useKeycloak();
    const parsedToken: ParsedToken | undefined = keycloak?.tokenParsed;
    const dispatch = useAppDispatch();
    const axios = useAxios(process.env.REACT_APP_CE_API_DOMAIN ?? "", keycloak);
    const [loadingCircularProgress, setLoadingCircularProgress] = useState(false);

    const loggedinState = keycloak?.authenticated ? (
        <span className="text-success">logged in</span>
    ) : (
        <span className="text-danger">NOT logged in</span>
    );

    const welcomeMessage =
        keycloak?.authenticated || (keycloak && parsedToken)
            ? `Welcome back ${parsedToken?.preferred_username ?? ''}!`
            : 'Welcome visitor. Please login to continue.';

    const handleClick = () => {
        setLoadingCircularProgress(true);
        axios?.current?.post(
            "/subscription/mockSubscriptionExpiration"
        ).then(r => {
            console.log("determinate if update toekn")
            // if (r?.data === 'true') {
            keycloak
                .updateToken(300)
                .then(refreshed => {
                    if (refreshed) {
                        dispatch(updateCurrentPlan(parsePlanFromKeycloak(keycloak)));
                        setLoadingCircularProgress(false);
                    }
                });
            // }
        })
    }

    return (
        <Layout>
            <h1 className="mt-5">CorrectEnglish Hub</h1>
            <div className="mb-5 lead text-muted">
                This is a new CorrectEnglish Frontend.
            </div>

            <p>You are: {loggedinState}</p>
            <p>{welcomeMessage}</p>
            {/*{*/}
            {/*    subsState.subs?.map((sub, index) =>*/}
            {/*        <Card key={index} sx={{ display: 'inline-block', transform: 'scale(0.8)' }}>*/}
            {/*            <Typography variant="body2" component={'span'}>*/}
            {/*                <p>Product Subtype: {sub.productSubType.name}</p>*/}
            {/*                <p>Start Date: {new Date(sub.startDateTime).toLocaleDateString('en-gb')}</p>*/}
            {/*                <p>Days: {sub.days}</p>*/}
            {/*                <br />*/}
            {/*            </Typography>*/}
            {/*        </Card>*/}
            {/*    )*/}
            {/*}*/}
            {/*<Button onClick={handleClick}>Mock Expiring Subscription</Button>*/}
            {/*<Card>*/}
                <CardActions style={{justifyContent: 'start', flexDirection:'row'}}>
                    <Box sx={{ m: 1, position: 'relative' }}>
                        <Button
                            id="registerPlanButton"
                            onClick={handleClick}
                            variant="contained"
                            disabled={loadingCircularProgress}
                            style={{textTransform:'none'}}
                        >
                            Mock Expiring Subscription
                        </Button>
                        {loadingCircularProgress && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: 'success',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                </CardActions>
            {/*</Card>*/}

            <p><CeExtButton /></p>
        </Layout>
    )
}

export default HomePage;
